/* eslint-disable class-methods-use-this */
import http from '../http-common';
import { encryptAES } from '../utils/cipherMethods';
import { encryptionKey } from '../utils/rules';

class AlertService {
	fetchAllAlerts(auth) {
		return http.get(`api/alert/get`, { user_key: encryptAES(auth.user_key[0], encryptionKey) });
	}

	fetchCustomAlerts(auth) {
		return http.post(`api/alert/getCustomAlerts`, { user_id: encryptAES(auth.user_key[0], encryptionKey), mail: encryptAES(auth.userDetails.user_mail, encryptionKey) });
	}

	deleteSingleAlert(alert, auth) {
		return http.post(`api/alert/delete`, {
			patient_id: encryptAES(alert.patient, encryptionKey),
			user_key: encryptAES(auth.user_key[0], encryptionKey)
		});
	}

	updateSingleMail(alert, auth) {
		return http.post(`api/alert/update/single`, {
			patient_id: encryptAES(alert.patient, encryptionKey),
			user_key: encryptAES(auth.user_key[0], encryptionKey)
		});
	}

	updateAllPending(auth) {
		return http.post(`api/alert/update/all`, {
			user_key: encryptAES(auth.user_key[0], encryptionKey)
		});
	}

	createDemoAlert(alert) {
		return http.post(`api/alert/create`, {
			patient: alert.patient,
			title: alert.title,
			content: alert.content
		});
	}
}

export default new AlertService();
