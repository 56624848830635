export const CHAIN_NOT_ALLOWED = 'CHAIN_NOT_ALLOWED';
export const CHAIN_IS_ALLOWED = 'CHAIN_IS_ALLOWED';
export const UPDATE_ACTIVE_MODAL = 'UPDATE_ACTIVE_MODAL';
export const UPDATE_BOOL_FILTER = 'UPDATE_BOOL_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const UPDATE_PROFILE_INFO_SUCCESS = 'UPDATE_PROFILE_INFO_SUCCESS';
export const UPDATE_PROFILE_INFO_ERROR = 'UPDATE_PROFILE_INFO_ERRORS';
export const TRANSACTION_CHECK_NOTIFICATION = 'TRANSACTION_CHECK_NOTIFICATION';
export const RESET_TRANSACTION_CHECK_NOTIFICATION = 'RESET_TRANSACTION_CHECK_NOTIFICATION';
export const HANDLE_MAIL_PREVIEW = 'HANDLE_MAIL_PREVIEW';
export const HANDLE_PARAMETER_PREVIEW = 'HANDLE_PARAMETER_PREVIEW';
