import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faLaptopMedical,
	faBed,
	faPlus
} from '@fortawesome/free-solid-svg-icons';
import '../../App.css';
import DeviceService from '../../services/deviceService';
import { useDispatch } from 'react-redux';
import { ADD_DEVICE_SUCCESS, ADD_DEVICE_ERROR, FETCH_DEVICES_SUCCESS, FETCH_DEVICES_ERROR } from '../../actions/types/deviceActionTypes';
import { decryptAES } from '../../utils/cipherMethods';
import { encryptionKey } from '../../utils/rules';
import SecondaryButton from '../Common/SecondaryButton';
import PrimaryButton from '../Common/PrimaryButton';

export default function AddDevice(props) {
	const { auth } = props;

	const [deviceID, setDeviceID] = useState('');
	const [isClickDisabled, setIsClickDisabled] = useState(false);
	const [patientID, setPatientID] = useState('');
	const [patientContactInfo, setPatientContactInfo] = useState('');
	const [isErrored, setIsErrored] = useState(false);
	const isAddAvailable = (deviceID && patientID && patientContactInfo);
	const dispatch = useDispatch();

	const handleAddDevice = (device) => {
		if (isAddAvailable && !isClickDisabled) {
			setIsClickDisabled(true);
			DeviceService.addSingleDevice(device, auth).then((singleDevice) => {
				dispatch({ type: ADD_DEVICE_SUCCESS, payload: singleDevice.data });
				// TODO :: update devices on redux instead of calling again.
				DeviceService.fetchAllDevices(auth).then((devices) => {
					const { data } = devices;
					const decryptedData = JSON.parse(decryptAES(data, encryptionKey)).text;
					dispatch({ type: FETCH_DEVICES_SUCCESS, payload: decryptedData });
					props.setIsOpen(false);
					setIsClickDisabled(false);
				}).catch((err) => {
					setIsClickDisabled(false);
					dispatch({ type: FETCH_DEVICES_ERROR, payload: err });
				});
			}).catch((err) => {
				setIsClickDisabled(false);
				dispatch({ type: ADD_DEVICE_ERROR, payload: err });
			})
		} else {
			setIsClickDisabled(false);
			setIsErrored(true);
		}
	}

	return (
		<>
			<div className='add-device-wrapper'>
				<div className='input-container'>
					<label for="device-id" className='input-label'>
						<FontAwesomeIcon icon={faLaptopMedical} />
						Device Id:
					</label>
					<input
						type='text'
						value={deviceID}
						onChange={(e) => setDeviceID(e.target.value)}
						placeholder="Device Id"
						id='device-id'
						className={isErrored && deviceID === '' ? 'is-field-empty' : ''}
					/>
				</div>
				<div className='input-container'>
					<label for="patient-id" className='input-label'>
						<FontAwesomeIcon icon={faBed} />
						Patient Id:
					</label>
					<input
						type='text'
						value={patientID}
						onChange={(e) => setPatientID(e.target.value)}
						placeholder="Patient Id"
						id='patient-id'
						className={isErrored && patientID === '' ? 'is-field-empty' : ''}
					/>
				</div>
				<div className='input-container'>
					<label for="patient-contact-info" className='input-label'>
						<FontAwesomeIcon icon={faBed} />
						Patient Contact Info:
					</label>
					<input
						type='text'
						value={patientContactInfo}
						onChange={(e) => setPatientContactInfo(e.target.value)}
						placeholder="Patient Contact Info"
						id='patient-contact-info'
						className={isErrored && patientContactInfo === '' ? 'is-field-empty' : ''}
					/>
				</div>
				<div className='add-device-button-container'>
					<SecondaryButton
						label='Cancel'
						onClick={() => props.setIsOpen(false)}
					/>
					<PrimaryButton
						label='Add Device'
						onClick={() => handleAddDevice({ deviceID, patientID, patientContactInfo })}
						buttonIcon={<FontAwesomeIcon icon={faPlus} style={{ color: '#F1EFFF' }} />}
						additionalClass={!isAddAvailable ? 'disabled' : ''}
					/>
				</div>
			</div>
		</>
	);
}
