import React from 'react'

export default function LandingContent(props) {
  return (
    <section className='section landing-about' id={props.sectionId}>
        <div className={`inline ${props.additionalClass}`}>
            <div className='landing-text'>
                <h1>
                    {props.title}
                </h1>
                <p>
                    {props.text}
                </p>
            </div>
            <div className='landing-illustration'>
                <img src={props.illustration} alt='illustration' />
            </div>
        </div>
    </section>
  )
}
