import React, { useEffect, useState } from "react";
import Select from "react-select";
import "../App.css";
import DeviceService from "../services/deviceService";
import AlertService from "../services/alertService";
import { decryptAES } from "../utils/cipherMethods";
import { encryptionKey } from "../utils/rules";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus } from '@fortawesome/free-solid-svg-icons';
// import PrimaryButton from '../components/Common/PrimaryButton';
import {
	NORMALIZE_ALARM_CODES,
	DEMO_DAY_MAIL_TITLES,
	DEMO_DAY_MAIL_CONTENT,
} from "../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import CipherMedLogo from '../assets/logo/ciphermedLogo.svg';

export default function DemoEvent({ props }) {
	const { auth, report, devices, ui, alerts } = props;
	const [demoDevices, setDemoDevices] = useState([]);
	const [selectedPatient, setSelectedPatient] = useState("index");
	const [selectedAlarm, setSelectedAlarm] = useState("index");
	const [createStatus, setCreateStatus] = useState(false);
	const [message, setMessage] = useState(false);
	const [slideRight, setSlideRight] = useState(false);
	const [slideDown, setSlideDown] = useState(false);

	useEffect(() => {
		if (Object.values(demoDevices).length === 0) {
			DeviceService.fetchDemoDevices()
				.then((devices) => {
					const { data } = devices;
					const decryptedData = JSON.parse(
						decryptAES(data, encryptionKey)
					).text;
					setDemoDevices(decryptedData);
				})
				.catch((err) => {
					throw new Error(err);
				});
		}
	}, []);

	let demoDeviceList = demoDevices.map((demoDevice) => ({
		value: demoDevice.patient_id,
		label: demoDevice.patient_id,
	}));
	demoDeviceList = [
		{ value: "index", label: "Please Select a Patient" },
		...demoDeviceList,
	];

	let alarmTypeSelection = Object.values(NORMALIZE_ALARM_CODES)
		.filter((a) => a !== "UNKNOWN")
		.map((alarmCode) => ({ value: alarmCode, label: alarmCode }));
	alarmTypeSelection = [
		{ value: "index", label: "Please Select a Alarm" },
		...alarmTypeSelection,
	].slice(0, -2);

	const handleCreateAlarm = (alarm) => {
		if (selectedPatient !== "index" || selectedAlarm !== "index") {
			setSlideDown(true);
			AlertService.createDemoAlert(alarm)
				.then(() => {
					setCreateStatus(true);
					setMessage(
						`You've succesfully created an alarm, time to check your mail!`
					);
				})
				.catch((err) => {
					console.error(err);
					setCreateStatus(true);
					setMessage(`Something went wrong over here, want some chocolate?`);
				});
		}
	};

	const dropdownOnChange = (value) => {
		setSelectedPatient(value);
		setSlideRight(true);
	};

	return (
		<div className="App">
			<div className="demo-wrapper" id="demoWrapper">
				<div
					className={`demo-animation-container ${slideRight ? "slide-right" : ""
						} ${slideDown ? "slide-down" : ""}`}
				>
					<div className="div1">
						<div className="demo-text-container">
							<h1>Introducing CipherMed Demo!</h1>
							<p>
								Experience the <span>future</span> of healthcare with <span>CipherMed's</span> demo app.
								Seamlessly and securely manage critical device information used
								by patients. You can start your demo by selecting a patient!
							</p>
						</div>
						<div className="demo-select-container">
							<Select
								options={demoDeviceList}
								closeMenuOnSelect
								isSearchable={false}
								onChange={(e) => dropdownOnChange(e.value)}
								defaultValue={{
									value: selectedPatient,
									label:
										selectedPatient === "index"
											? "Please Select a Patient"
											: selectedPatient,
								}}
								theme={(theme) => ({
									...theme,
									borderRadius: "6px",
									colors: {
										...theme.colors,
										neutral0: "#14025C",
										neutral20: "#F1EFFF1A",
										neutral30: "#F1EFFF66",
										primary: "#768CFF",
										primary25: "#3204EB66",
										neutral80: "#F1EFFF",
										neutral90: "#F1EFFF",
									},
								})}
							/>
							<span
								id="line1"
								className={slideRight ? "line-slide-right" : ""}
							></span>
						</div>
					</div>
					<div className="div2">
						<div className="demo-text-container">
							<h1>Healthcare at Your Fingertips</h1>
							<p>
								Discover the transformative power of <span>CipherMed</span> today! Empower
								healthcare professionals to provide efficient, precise and
								<span> patient-centered</span> care. In this step, you are ready to create
								your first alarm.
							</p>
						</div>
						<div className="demo-select-container">
							<Select
								options={alarmTypeSelection}
								closeMenuOnSelect
								isSearchable={false}
								onChange={(e) => setSelectedAlarm(e.value)}
								defaultValue={{
									value: selectedAlarm,
									label:
										selectedAlarm === "index"
											? "Please Select Alarm"
											: selectedAlarm,
								}}
								theme={(theme) => ({
									...theme,
									borderRadius: "6px",
									colors: {
										...theme.colors,
										neutral0: "#14025C",
										neutral20: "#F1EFFF1A",
										neutral30: "#F1EFFF66",
										primary: "#768CFF",
										primary25: "#3204EB66",
										neutral80: "#F1EFFF",
										neutral90: "#F1EFFF",
									},
								})}
							/>
							<button
								className="secondary-button demo-button"
								onClick={() =>
									handleCreateAlarm({
										patient: selectedPatient,
										title: DEMO_DAY_MAIL_TITLES[selectedAlarm],
										content: DEMO_DAY_MAIL_CONTENT[selectedAlarm],
									})
								}
							>
								<FontAwesomeIcon icon={faBell} style={{ color: '#F1EBFF' }} />
								Create Alarm
							</button>
							<span
								id="line2"
								className={slideDown ? "line-slide-down" : ""}
							></span>
						</div>
					</div>
					<div className="div3">
						<div className="demo-text-container">
							<img src={CipherMedLogo} alt="Ciphermed Logo"/>
							<p id="last-box-text">
								You have succesfully experienced the future of healthcare today.
								Please <span>check your e-mail</span> now for a suprise!
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
