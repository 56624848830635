import React from 'react';

export default function DevicesLoader(props) {
    return (
        <div className={`shimmer-container ${props.height}`}>
            <div className='shimmer-bg'></div>
            <div className='shimmer-bg'></div>
            <div className='shimmer-bg'></div>
            <div className='shimmer-bg'></div>
            <div className='shimmer-bg'></div>
        </div>
    )
}