import React, { useEffect, useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Devices from '../pages/devices';
import Alerts from '../pages/alerts';
import Reports from '../pages/reports';
import DemoEvent from '../pages/demoEvent';
import { CHAIN_NOT_ALLOWED, CHAIN_IS_ALLOWED } from '../actions/types/uiActionTypes';
import { FETCH_ALERTS_SUCCESS } from '../actions/types/alertActionTypes';
import { AUTH_SUCCESS } from '../actions/types/authActionTypes';
import { LOGOUT_OPERATION } from '../actions/types/authActionTypes';
import { allowedChains } from '../utils/rules';
import { decryptAES } from '../utils/cipherMethods';
import { encryptionKey } from '../utils/rules';
import AlertService from '../services/alertService';
import ModalWrapper from '../components/Common/ModalWrapper';
import { getAllPairedDevices } from '../utils/methods';
import { SET_PAIRED_DEVICES } from '../actions/types/deviceActionTypes';
import Users from './users';
import UserService from '../services/userService';

export default function Main(props) {
  const { auth, ui } = props;
  const { page } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUserLoggedIn = auth.isAuthorized;
  const [isPairedDevicesLoading, setIsPairedDevicesLoading] = useState(true);
  const [userList, setUserList] = useState({});

  if (!isUserLoggedIn) {
    navigate(`/`, { replace: false });
  }

  const accountWasChanged = useCallback((accounts) => {
    if (accounts.length === 0) {
      dispatch({ type: LOGOUT_OPERATION });
    } else {
      if (accounts !== auth.user_key) {
        dispatch({ type: AUTH_SUCCESS, payload: accounts });
      }
    }
  }, [dispatch, auth]);

  const chainWasChanged = useCallback((chain) => {
    if (!allowedChains.includes(chain)) {
      dispatch({ type: CHAIN_NOT_ALLOWED });
    } else {
      if (auth.isAuthorized && auth?.userDetails.user_type !== 'db_admin') {
        getAllPairedDevices().then(pairedDevices => dispatch({ type: SET_PAIRED_DEVICES, payload: pairedDevices }));
      }
      dispatch({ type: CHAIN_IS_ALLOWED });
    }
  }, [dispatch]);

  useEffect(() => {
    if (auth.isAuthorized) {
      if (auth?.userDetails.user_type === 'db_admin') {
        AlertService.fetchAllAlerts(auth).then(alerts => {
          const { data } = alerts;
          const decryptedData = JSON.parse(decryptAES(data, encryptionKey)).text;
          dispatch({ type: FETCH_ALERTS_SUCCESS, payload: decryptedData });
        }).catch((err) => {
          throw new Error(err);
        });
        if (Object.values(userList).length === 0) {
          UserService.fetchAllUsers(auth).then(users => {
            const { data } = users;
            const decryptedData = JSON.parse(decryptAES(data, encryptionKey)).text;
            setUserList(decryptedData);
          }).catch((err) => {
            throw new Error(err);
          });
        }
      } else {
        AlertService.fetchCustomAlerts(auth).then(alerts => {
          const { data } = alerts;
          const decryptedData = JSON.parse(decryptAES(data, encryptionKey)).text;
          dispatch({ type: FETCH_ALERTS_SUCCESS, payload: decryptedData });
        }).catch((err) => {
          throw new Error(err);
        });
      }
    }
  }, [auth, ui]);

  useEffect(() => {
    if (window?.ethereum?.chainId && !allowedChains.includes(window?.ethereum?.chainId)) {
      dispatch({ type: CHAIN_NOT_ALLOWED });
    }
  }, []);

  useEffect(() => {
    if (auth.isAuthorized && auth?.userDetails.user_type !== 'db_admin') {
      getAllPairedDevices().then(pairedDevices => {
        dispatch({ type: SET_PAIRED_DEVICES, payload: pairedDevices });
        setIsPairedDevicesLoading(false);
      });
    }
  }, [auth]);

  useEffect(() => {
    return () => {
      if (page !== 'demo-day') {
        try {
          window.ethereum.on('accountsChanged', accountWasChanged);
          window.ethereum.on('chainChanged', chainWasChanged);
        } catch (error) {
          console.error('You need to install Metamask!', error);
        }
      }
    }
  }, [accountWasChanged, chainWasChanged]);

  return (
    <>
      {
        {
          'dashboard': <Devices props={props} isPairedDevicesLoading={isPairedDevicesLoading} />,
          'users': <Users props={props} userList={userList} />,
          'reports': <Reports props={props} />,
          'mail-alerts': <Alerts props={props} />,
          'demo-day': <DemoEvent props={props} />,
        }[page]
      }
      <ModalWrapper {...props} />
    </>
  );
}
