import Landing from './pages/landing';
import Main from './pages/main';

export const routes = [
  {
    path: '/',
    exact: true,
    component: Landing,
  },
  {
    path: '/*',
    exact: true,
    component: Landing,
  },
  {
    path: '/main/:page/:id?',
    exact: true,
    component: Main,
  }
];
