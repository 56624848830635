import React, { useState, useEffect } from 'react';
import '../App.css';
import SidePanel from '../components/Common/SidePanel';
import ContentHeader from '../components/Common/ContentHeader';
import ScrollUpButton from '../components/Common/ScrollUpButton';
import UserBox from '../components/Device/UserBox';
import DevicesLoader from '../components/Common/DevicesLoader';

export default function Users({ props, userList }) {
	const { auth, alerts, ui } = props;
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (Object.values(userList).length > 0) {
			setIsLoading(false);
		}
	}, [userList]);
	return (
		<div className="App">
			<header className="devices">
				<SidePanel
					authUser={auth}
					alerts={alerts}
				/>
				<div className='main-content-wrapper'>
					<ContentHeader
						contentHeader={`USERS ${userList.length > 0 ? `(${userList.length})` : ''}`}
						authUser={auth}
						ui={ui}
						alerts={alerts}
						auth={auth}
						page="users"
					/>{
						isLoading ? (<div className='pair-devices-loaders'>
							<DevicesLoader height="height-large" />
							<DevicesLoader height="height-large" />
						</div>) : (
							<div className='content-table'>
								<div className='device-box-table'>
									{userList && Object.values(userList)?.map(user => (
										<UserBox
											create_date={user?.create_date}
											user_key={user?.user_key}
											user_location={user?.user_location}
											user_mail={user?.user_mail}
											user_name={user?.user_name}
											user_phone={user?.user_phone}
											user_type={user?.user_type}
										/>
									))}
								</div>
							</div>
						)
					}
				</div>
			</header >
			<ScrollUpButton />
		</div >
	)
}
