import React from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBroom,
    faFilter
} from '@fortawesome/free-solid-svg-icons';
import { UPDATE_BOOL_FILTER, CLEAR_FILTERS } from '../../actions/types/uiActionTypes';
import SecondaryButton from '../Common/SecondaryButton';

export default function FilterDropdown({ device_filters }) {
    const dispatch = useDispatch();
    const handleOnChange = (e) => {
        dispatch({ type: UPDATE_BOOL_FILTER, payload: { target: e.target.id, value: e.target.value !== "true" } })
    }

    const handleOnSelect = (e) => {
        dispatch({ type: UPDATE_BOOL_FILTER, payload: { target: 'sort', value: e.value } })
    }

    const handleOnClear = () => {
        dispatch({ type: CLEAR_FILTERS })
    }

    return (
        <>
            <div className='dropdown-header-container'>
                <h1>
                    <FontAwesomeIcon icon={faFilter} style={{ color: '##F1EBFF' }} />
                    Filter Results
                </h1>
                {Object.entries(device_filters).some(filter => (filter[1].prop !== null && filter[1].prop !== 'createAsc')) && (
                    <SecondaryButton
                        onClick={handleOnClear}
                        label="Clear Filters"
                        title="Clear Filters"
                        buttonIcon={<FontAwesomeIcon icon={faBroom} style={{ color: '#F1EFFF' }} />}
                    />
                )}
            </div>
            {Object.entries(device_filters).map(([key, value]) => (
                <div className='filter-toggle-container'>
                    <div>
                        <FontAwesomeIcon icon={value.icon} style={{ color: '#F1EFFF' }} />
                        <p className="prop-title">{value.text}:</p>
                    </div>
                    {key === 'sort' ? (
                        <>
                            <Select
                                options={[
                                    { value: 'createAsc', label: 'Date Created (Asc)' },
                                    { value: 'createDesc', label: 'Date Created (Desc)' },
                                    { value: 'pairAsc', label: 'Date Paired (Asc)' },
                                    { value: 'pairDesc', label: 'Date Paired (Desc)' },
                                ]}
                                closeMenuOnSelect
                                onChange={handleOnSelect}
                                defaultValue={{ value: 'createAsc', label: 'Date Created (Asc)' }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: '6px',
                                    colors: {
                                        ...theme.colors,
                                        neutral0: '#14025C',
                                        neutral20: '#F1EFFF1A',
                                        neutral30: '#F1EFFF66',
                                        primary: '#768CFF',
                                        primary25: '#3204EB66',
                                        neutral80: '#F1EFFF',
                                        neutral90: '#F1EFFF'
                                    },
                                })}
                            />
                        </>
                    ) : (
                        <label className="switch">
                            <input
                                id={key}
                                value={value.prop}
                                checked={value.prop}
                                type="checkbox"
                                onChange={handleOnChange}
                            />
                            <span className="slider round"></span>
                        </label>
                    )}
                </div>
            ))}
        </>
    )
}