import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as Sentry from "@sentry/react";
import { routes } from './routes';
import NotificationCenter from './components/NotificationCenter';

function App(props) {
  useEffect(() => {
    Sentry.init({
      dsn: "https://a73669e6c32445b2b68350f41e35e356@o4505312148586496.ingest.sentry.io/4505312150421504",
      integrations: [new Sentry.BrowserTracing()],
      // Performance Monitoring
      tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    });
  }, []);
  return (
    <>
      <NotificationCenter />
      <Router>
        <Routes>
          {routes.map((route) => <Route path={route.path} element={<route.component {...props} />} />)}
        </Routes>
      </Router>
    </>
  );
}

const mapStateToProps = (state) => ({
  devices: state.deviceReducer,
  ui: state.uiReducer,
  auth: state.authReducer,
  alerts: state.alertReducer,
  report: state.reportReducer
});

export default compose(
  connect(mapStateToProps),
)(App);
