import React, { useState, useEffect } from 'react';
import '../../App.css';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { ethers } from "ethers";
import DeviceService from '../../services/deviceService';
import { DeviceContractAddress } from "../../config";
import DeviceAbi from '../../dapp/build/contracts/DeviceContract.json';
import { DEVICE_PAIR_SUCCESS, DEVICE_PAIR_ERROR, ETHEREUM_CONFIG_ERROR } from '../../actions/types/deviceActionTypes';
import { TRANSACTION_CHECK_NOTIFICATION, RESET_TRANSACTION_CHECK_NOTIFICATION } from '../../actions/types/uiActionTypes';
import moment from 'moment/moment';
import { decryptAES } from '../../utils/cipherMethods';
import { encryptionKey } from '../../utils/rules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import PairUserTypeInput from './PairUserTypeInput';
import SecondaryButton from '../Common/SecondaryButton';
import PrimaryButton from '../Common/PrimaryButton';

export default function PairDevice(props) {
	const { auth, ui } = props;
	const [pairCode, setPairCode] = useState('');
	const [pairType, setPairType] = useState(auth?.userDetails.user_type);
	const [ongoingTransaction, setOngoingTransaction] = useState(false);
	const dispatch = useDispatch();
	const [pairMode, setPairMode] = useState('user_select');
	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		dispatch({ type: RESET_TRANSACTION_CHECK_NOTIFICATION });
	}, []);

	const handlePairDevice = async deviceConfig => {
		const { device_id, patient_id, patient_contact } = deviceConfig;
		let device = {
			deviceId: device_id,
			patientId: patient_id,
			patientContact: patient_contact,
			ownerType: pairType
		}

		try {
			const { ethereum } = window;
			if (ethereum) {
				const provider = new ethers.providers.Web3Provider(ethereum)
				const signer = provider.getSigner();
				const DeviceContract = new ethers.Contract(
					DeviceContractAddress,
					DeviceAbi.abi,
					signer
				)
				const dateTime = moment().format('DD.MM.YYYY hh:mm:ss');
				DeviceContract.addDevice(
					ethers.utils.formatBytes32String(device.deviceId),
					ethers.utils.formatBytes32String(device.patientId),
					ethers.utils.formatBytes32String(dateTime),
					ethers.utils.formatBytes32String(device.patientContact),
					ethers.utils.formatBytes32String(device.ownerType),
				).then(res => {
					const { hash } = res;
					dispatch({ type: TRANSACTION_CHECK_NOTIFICATION, payload: hash });
					DeviceService.updatePairStatus(device.deviceId, pairType, auth).then(success => {
						dispatch({ type: DEVICE_PAIR_SUCCESS, payload: success.data });
						setOngoingTransaction(false);
						setPairMode('success');
					}).catch((err) => {
						setOngoingTransaction(false);
						setPairMode('failed');
						if (err.toString().indexOf('ACTION_REJECTED') > -1) {
							setErrorMessage(`You've rejected the pair request.`);
						} else {
							setErrorMessage(err.toString());
						}
						dispatch({ type: DEVICE_PAIR_ERROR, payload: err });
					})
				}).catch(err => {
					setPairMode('failed');
					setOngoingTransaction(false);
					if (err.toString().indexOf('ACTION_REJECTED') > -1) {
						setErrorMessage(`You've rejected the pair request.`);
					} else {
						setErrorMessage(err.toString());
					}
					dispatch({ type: DEVICE_PAIR_ERROR, payload: err })
				})
			}
		} catch (error) {
			dispatch({ type: ETHEREUM_CONFIG_ERROR, payload: error })
		}
	}

	const initDevicePair = (pairCode, pairType) => {
		setOngoingTransaction(true);
		DeviceService.getDeviceConfig(pairCode, pairType, auth).then(deviceConfig => {
			const { data } = deviceConfig;
			const decryptedData = JSON.parse(decryptAES(data, encryptionKey)).text;
			handlePairDevice(decryptedData);
		}).catch((err) => {
			setOngoingTransaction(false);
			setPairMode('failed');
			dispatch({ type: DEVICE_PAIR_ERROR, payload: err })
			if (err.toString().indexOf('ACTION_REJECTED') > -1) {
				setErrorMessage(`You've rejected the pair request.`);
			} else {
				setErrorMessage(err.toString());
			}
			setErrorMessage()
		})
	};

	const onChangeValue = e => (
		setPairType(e.target.id)
	)

	const handleCloseModal = () => {
		props.setIsOpen(false);
	}

	const handlePairButton = () => {
		initDevicePair(pairCode.trim(), pairType);
	}

	return (
		<>
			<div className='add-device-wrapper'>
				{pairMode === 'user_select' &&
					<>
						<PairUserTypeInput
							checkedDoctor={pairType === "doctor"}
							checkedDeviceExpert={pairType === "device_expert"}
							onClick={onChangeValue}
						/>
						<div className='add-device-button-container'>
							<SecondaryButton
								label='Cancel'
								onClick={handleCloseModal}
							/>
							<PrimaryButton
								label='Continue'
								onClick={() => setPairMode('code_entry')}
							/>
						</div>
					</>
				}
				{(pairMode === 'code_entry' || ongoingTransaction) &&
					<>
						<div className='input-container'>
							<label for="pair-code" className='input-label'>
								<FontAwesomeIcon icon={faKey} style={{ color: '#F1EBFF' }} />
								Pair Code
							</label>
							<input
								type='text'
								value={pairCode}
								onChange={(e) => setPairCode(e.target.value)}
								placeholder="Pair Code"
								id='pair-code'
							/>
						</div>
						<div className='add-device-button-container'>
							<SecondaryButton
								label='Back'
								onClick={() => !ongoingTransaction && setPairMode('user_select')}
							/>
							<PrimaryButton
								label={ongoingTransaction ? 'Processing...' : 'Pair Device'}
								onClick={handlePairButton}
								disabled={pairCode === ''}
								additionalClass={classNames({ 'disabled': pairCode === '' })}
							/>
						</div>
					</>
				}
				{(ui.displayTestnetInfo.prop && pairMode === 'success') &&
					<div className='pair-device-wrapper'>
						<div className='result-animation-wrapper success'>
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
								<circle class="path circle" fill="#04CB00" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
								<polyline class="path check" fill="none" stroke="#FFFFFF" stroke-width="12" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
							</svg>
							<p>{ui.displayTestnetInfo.message} <a className='etherscan' href={ui.displayTestnetInfo.url} target='_blank' rel="noreferrer">Etherscan</a></p>
						</div>
						<div className='add-device-button-container'>
							<PrimaryButton
								label='Close'
								onClick={handleCloseModal}
							/>
						</div>
					</div>
				}
				{pairMode === 'failed' &&
					<>
						<div className='result-animation-wrapper fail'>
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
								<circle class="path circle" fill="#D80000" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
								<line class="path line" fill="none" stroke="#FFFFFF" stroke-width="12" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
								<line class="path line" fill="none" stroke="#FFFFFF" stroke-width="12" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
							</svg>
							<p>{errorMessage?.length > 0 ? errorMessage : 'Oops! Something went wrong.'}</p>
						</div>
						<div className='add-device-button-container'>
							<PrimaryButton
								label='Close'
								onClick={handleCloseModal}
							/>
						</div>
					</>
				}
			</div>
		</>
	);
}

