/* eslint-disable no-useless-escape */
import React from 'react';
import '../../App.css';
import PrimaryButton from '../Common/PrimaryButton';

export default function PreviewMail(props) {
	const { ui } = props;

	const data = `<!DOCTYPE html>
	<html lang="en">
	<head>
		<meta charset="UTF-8">
		<meta http-equiv="X-UA-Compatible" content="IE=edge">
		<meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=no;">
		<meta name="viewport" content="width=600,initial-scale = 2.3,user-scalable=no">
		<meta name="viewport" content="width=device-width">
		<meta name="format-detection" content="date=no">
		<meta name="format-detection" content="telephone=no">
		<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
		<meta name="color-scheme" content="light dark">
		<meta name="supported-color-schemes" content="light dark">
		<title>CipherMed Alert Email</title>
	
		<style>
        @import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300;500&family=Roboto:wght@400;700&display=swap');

        body {
            margin: 0;
            font-family: 'Roboto', sans-serif;
        }

        .email-wrapper {
            width: 100%;
            height: 100%;
			padding: 32px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 8px;
			
        }

		.email-preview-container {
			max-height: 60vh;
			overflow-y: scroll;
			overflow-x: hidden;
		}

        .email-table {
            min-width: fit-content;
			height: 100%
            border-collapse: collapse;
            border: none;
        }

        thead,
        .table-foot-button {
            width: 100%;
            border-bottom: 1px solid #F1EFFF33;
        }

        .table-header-desc td {
            padding: 4px 0 12px;
        }

        * {
            color: #F1EBFF;
        }

        .table-header-title {
            font-size: 26px;
            font-weight: 500;
        }

		#tbody-first {
			border-top: 1px solid #F1EFFF33;
		}

        .table-header-desc,
        tbody td p {
            opacity: 70%;
            font-size: 18px;
            font-weight: 400;
            display: inline;
        }

        tbody>tr:first-of-type>td {
            padding: 32px 0 6px;
        }

        tbody>tr>td {
            padding: 6px 0;
        }

        tbody>tr:last-of-type>td {
            padding-bottom: 32px;
        }

        tbody span {
            opacity: 100%;
            font-size: 18px;
            font-weight: 400;
        }

        .table-foot-button td,
        .table-foot-credits td {
            padding: 16px 0;
        }

        .table-foot-credits td {
            width: 100%;
            text-align: center;
            opacity: 70%;
            font-size: 16px;
            font-weight: 400;
        }

		#footer-first {
			border-top: 1px solid #F1EFFF33;
		}

		.table-foot-credits a {
			color: #ff124f;
			font-size: 14px;
            cursor: pointer
		}

		.table-button {
			padding: 8px 16px;
            border-radius: 0;
            border: none;
            background-color: #3204EB;
            font-size: 14px;
            line-height: 14px;
            color: #F1EBFF;
            font-weight: bold;
            cursor: pointer;
			text-decoration: none;
		}

        .credit {
            font-size: 14px;
            font-family: 'Roboto', sans-serif;
        }
    </style>
	</head>
	
	<body>
		<div class="email-wrapper">
			<table class="email-table">
				<thead>
					<tr class="table-header-title">
						<td>
						${ui?.previewContent.title}
						</td>
					</tr>
					<tr class="table-header-desc">
						<td>
						
						</td>
					</tr>
				</thead>
				<tbody>
					<tr class="table-body-detail">
						<td id="tbody-first">
							<span>Detail: </span>
							<p>${ui?.previewContent.content}</p>
						</td>
					</tr>
					<td></td>
					<tr class="table-body-detail">
						<td>
							<span>Patient: </span>
							<p>${ui?.previewContent.patient}</p>
						</td>
					</tr>
					<tr class="table-body-detail">
						<td>
							<span>Doctor: </span>
							<p>${ui?.previewContent.doctor.split('_')[0]}</p>
						</td>
					</tr>
					<tr class="table-body-detail">
						<td>
							<span>Device Expert: </span>
							<p>${ui?.previewContent.device_expert.split('_')[0]}</p>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr class="table-foot-credits">
						<td id="footer-first">
							This alert was created by ${ui?.previewContent.patient}’s own device.
						</td>
					</tr>
					<tr class="table-foot-credits">
						<td>
							©2023 <a href="https://ciphermed.app/" target="_blank">Project Ciphermed</a>. All Rights
							Reserved
						</td>
					</tr>
					</tfood>
			</table>
		</div>
	</body>
	</html>`;

	return (
		<div className='add-device-wrapper'>
			<div dangerouslySetInnerHTML={{ __html: data }} className='email-preview-container' />
			<div className='add-device-button-container'>
				<PrimaryButton
					label='Close'
					onClick={() => props.setIsOpen('previewMail')}
				/>
			</div>
		</div >
	);
}
