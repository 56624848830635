import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRotateRight
} from '@fortawesome/free-solid-svg-icons';
import SecondaryButton from './SecondaryButton';

export default function NoNotifications(props) {
    return (
        <div class="bell-animate">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
                <g class="too-big-actually">
                    <g class="bell-whole">
                        <path class="bell-part bell-part--ringer" d="M9.5,17.5a2,2,0,0,0,2-2h-4A2,2,0,0,0,9.5,17.5Z" />
                        <path class="bell-part bell-part--main" d="M16.23,12.82c-.6-.65-1.73-1.62-1.73-4.82a4.93,4.93,0,0,0-4-4.85V2.5a1,1,0,0,0-2,0v.65A4.94,4.94,0,0,0,4.5,8c0,3.2-1.13,4.17-1.73,4.82a1,1,0,0,0-.27.68,1,1,0,0,0,1,1h12a1,1,0,0,0,1-1A1,1,0,0,0,16.23,12.82Z" />
                    </g>
                </g>
            </svg>
            <p>{props.message}</p>
            <SecondaryButton
                label="Refresh Page"
                title="Refresh Page"
                onClick={() => window.location.reload(false)}
                buttonIcon={<FontAwesomeIcon icon={faArrowRotateRight} style={{ color: '#F1EFFF' }} />}
            />
        </div>
    )
}