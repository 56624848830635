import { currDate, convertBase64ToFile } from '../utils/methods';

export const exportJSONFile = (rawData, fileTitle = 'CipherMed') => {
    const jsonObject = JSON.stringify(rawData);
    const exportedFilename = `${fileTitle}_${currDate()}_rawData.json`;

    let blob = new Blob([jsonObject], { type: 'application/json; charset=utf-8;' });
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFilename);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export const exportChart = (chart, fileTitle = 'CipherMed') => {
    const exportedFilename = `${fileTitle}_${currDate()}_chart.png`;
    const pngObject = convertBase64ToFile(chart, fileTitle);

    let blob = new Blob([pngObject], { type: 'data:application/octet-stream;base64' });
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFilename);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}