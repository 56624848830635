export const normalizeUserTypes = {
    'db_admin': "Database Admin",
    'doctor': "Doctor",
    'device_expert': "Device Expert",
    'guest': "Guest",
    null: "Guest"
};

export const defaultFilters = {
    sort: 'pairDate',
    isAccessible: true,
    isPairCodeActive: true,
    isAlertExists: true,
    pairedStatus: 'all|doctor|device_expert',
    belongsToDoctor: null,
    belongsToDeviceExpert: null,
};

export const NORMALIZE_ALARM_CODES = {
    0: "UNKNOWN",
    1: "LOW PRESSURE",
    2: "HIGH PRESSURE",
    4: "LOW VOLUME",
    8: "HIGH VOLUME",
    9: "LOW RATE",
    11: "HIGH RATE",
    16: "LOW RATE",
    32: "HIGH RATE"
}

export const NORMALIZE_PATTERN_CODES = {
    0: "Square",
    1: "Ramp",
    2: "Sine",
}

export const NORMALIZE_PATIENT_CODES = {
    1: "Newborn",
    2: "Baby",
    3: "Adult",
}

export const PARAMATER_GROUPS = [
    'vtidal',
    'pinsp',
    'sense',
]

//in seconds
export const ALARM_INTERVAL = 30;
export const ALARM_COUNT = [20, 25];
export const CONTINUOUS_ALARM_COUNT = [5, 20];

//in minutes
export const PAUSE_INTERVAL = [20, 50];

export const DEMO_DAY_MAIL_TITLES = {
    "UNKNOWN": 'UNKNOWN',
    "LOW PRESSURE": `URGENT: Low Pressure Alarm Activated - Patient's Health Status Alert`,
    "HIGH PRESSURE": `URGENT: High Pressure Alarm Activated - Patient's Health Status Alert`,
    "LOW VOLUME": `ALERT: Low Volume Alarm Activated - Patient's Health Status Update`,
    "HIGH VOLUME": `URGENT: High Volume Alarm Activated - Patient's Health Status Alert`,
    "LOW RATE": `ALERT: Low Rate Alarm Activated - Patient's Health Status Update`,
    "HIGH RATE": `URGENT: High Rate Alarm Activated - Patient's Health Status Alert`,
}

export const DEMO_DAY_MAIL_CONTENT = {
    "UNKNOWN": 'UNKNOWN',
    "LOW PRESSURE": `This is an urgent notification regarding the patient's health status. 
    The low pressure alarm has been activated on the exhale device, indicating a potential issue with the air pressure. 
    Immediate attention and intervention are required to ensure the patient's safety and well-being.
    <br>
    <br>Please take the following actions:
    <br><br>
    Check the connections and tubing for any obstructions or leaks.<br>
    Verify that the pressure settings on the exhale device are correct.<br>
    Consult the patient's medical history and consider any relevant factors.<br>`,
    "HIGH PRESSURE": `This is an urgent notification regarding the patient's health status. 
    The high pressure alarm has been triggered on the exhale device, indicating a potential risk of excessive pressure. 
    Immediate action is required to prevent any harm to the patient.<br>
    <br>Please follow the steps below:
    <br><br>
    Check the exhale device settings and ensure they are within the recommended range.<br>
    Examine the tubing and connections for any blockages or restrictions.<br>
    Evaluate the patient's condition and consult their medical history to identify potential causes for the high pressure reading.<br>`,
    "LOW VOLUME": `We would like to bring to your attention an important update regarding the patient's health status. 
    The exhale device has detected a low volume alarm, indicating a potential issue with the delivered air volume. 
    Immediate attention is necessary to ensure the patient's safety and optimal treatment.<br>
    <br>To address this situation, please perform the following steps:
    <br><br>
    Check the tubing and connections for any blockages or kinks.<br>
    Verify that the exhale device is set to the appropriate volume settings.<br>
    Assess the patient's breathing patterns and consult their medical records for relevant information.<br>`,
    "HIGH VOLUME": `This is an urgent notification regarding the patient's health status. 
    The exhale device has detected a high volume alarm, indicating a potential risk of excessive air volume being delivered. 
    Immediate action is crucial to prevent any complications for the patient.<br>
    <br>To address this situation, please take the following steps:
    <br><br>
    Check the exhale device settings and ensure they are within the recommended range.<br>
    Evaluate the tubing and connections for any obstructions or restrictions.<br>
    Assess the patient's breathing patterns and consult their medical history for any relevant information.<br>`,
    "LOW RATE": `We would like to bring to your attention an important update regarding the patient's health status. 
    The exhale device has detected a low rate alarm, indicating a potential issue with the breathing rate. 
    Immediate attention is necessary to ensure the patient's well-being.
    <br>To address this situation, please perform the following steps:
    <br><br>
    Verify that the exhale device is set to the appropriate rate settings.<br>
    Assess the patient's breathing patterns and monitor their vital signs.<br>
    Consult the patient's medical records for any relevant information that might explain the low rate alarm.<br>`,
    "HIGH RATE": `This is an urgent notification regarding the patient's health status. 
    The exhale device has detected a high rate alarm, indicating a potential risk of an elevated breathing rate. 
    Immediate action is crucial to ensure the patient's safety and well-being.<br>
    <br>To address this situation, please take the following steps:
    <br><br>
    Verify that the exhale device is set to the appropriate rate settings.<br>
    Assess the patient's breathing patterns and monitor their vital signs.<br>
    Consult the patient's medical records for any relevant information that might explain the high rate alarm.<br>`,
}
