import { FETCH_DEVICES_SUCCESS, SET_PAIRED_DEVICES, DELETE_DEVICE_SUCCESS } from '../actions/types/deviceActionTypes';

const INITIAL_DEVICE_STATE = {
  baseDevices: [],
  pairedDevices: [],
};

export const deviceReducer = (state = INITIAL_DEVICE_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DEVICES_SUCCESS:
      return { ...state, baseDevices: payload };
    case SET_PAIRED_DEVICES:
      return { ...state, pairedDevices: payload };
    case DELETE_DEVICE_SUCCESS:
      return { ...state, baseDevices: state.baseDevices.filter((device) => device.id !== action.deletedDevice.id) };
    default:
      return state;
  }
};
