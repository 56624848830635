import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ethers } from "ethers";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../App.css';
import SidePanel from '../components/Common/SidePanel';
import Alarms from '../components/Reports/Alarms';
import { useNavigate } from 'react-router-dom';
import Pauses from '../components/Reports/Pauses';
import DeviceService from '../services/deviceService';
import { processDummyData } from '../utils/helpers';
import { SET_DUMMY_DATA } from '../actions/types/reportActionTypes';
import ContentHeader from '../components/Common/ContentHeader';
import { decryptAES } from '../utils/cipherMethods';
import { encryptionKey } from '../utils/rules';
import { isHexValid } from '../utils/methods';
import { getParameterDetails } from '../utils/methods';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faCalendarDays, faChartLine, faFileExport, faIdBadge, faChartColumn } from '@fortawesome/free-solid-svg-icons';
import SecondaryButton from '../components/Common/SecondaryButton';
import Badge from '../components/Common/Badge';
import ScrollUpButton from '../components/Common/ScrollUpButton';
import DevicesLoader from '../components/Common/DevicesLoader';
import { HANDLE_PRIVACY_MODAL } from '../actions/types/deviceActionTypes';

export default function Reports({ props }) {
	const { auth, report, devices, ui, alerts } = props;
	const { alarms, paramaters, pauses, rawData } = report;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [paramaterDetails, setParamaterDetails] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [displayMode, setDisplayMode] = useState('Alarms');
	const { id } = useParams();

	let patientTypeBadge;
	let patternTypeBadge;

	switch (paramaterDetails?.patient) {
		case 'Newborn':
			patientTypeBadge = 'badge-pink';
			break;
		case 'Baby':
			patientTypeBadge = 'badge-light-purple';
			break;
		case 'Adult':
			patientTypeBadge = 'badge-purple';
			break;
		default:
			patientTypeBadge = 'badge-grey'
			break;
	}

	switch (paramaterDetails?.pattern) {
		case 'Square':
			patternTypeBadge = 'badge-pink';
			break;
		case 'Ramp':
			patternTypeBadge = 'badge-light-purple';
			break;
		case 'Sine':
			patternTypeBadge = 'badge-purple';
			break;
		default:
			patternTypeBadge = 'badge-grey'
			break;
	}

	if (!auth.isAuthorized || auth.userDetails.user_type === 'db_admin') {
		navigate(`/main/dashboard`, { replace: false });
	}

	useEffect(() => {
		if (auth.isAuthorized && id !== 'index') {
			DeviceService.getDummyData(auth).then(dummy => {
				const { data } = dummy;
				const decryptedData = JSON.parse(decryptAES(data, encryptionKey)).text;
				processDummyData(decryptedData, dispatch);
				dispatch({ type: SET_DUMMY_DATA, payload: decryptedData });
			}).catch((err) => {
				throw new Error(err);
			});
		}
	}, [id]);

	useEffect(() => {
		if (paramaters !== []) {
			setParamaterDetails(getParameterDetails(paramaters, setIsLoading));
		}
	}, [id, paramaters]);

	const handleOnChange = (e) => {
		setIsLoading(true);
		navigate(`/main/reports/${e.value}`, { replace: true });
	}

	let patientOptions = Object.values(devices.pairedDevices).filter(aa => aa[3]).map(pairedDevice => isHexValid(pairedDevice.patientId._hex) && (
		{ value: ethers.utils.parseBytes32String(pairedDevice.patientId._hex), label: ethers.utils.parseBytes32String(pairedDevice.patientId._hex) }
	)).filter(a => a);
	patientOptions = [{ value: 'index', label: 'Please Select a Patient' }, ...patientOptions];

	// const isPatientAvailable = patientOptions.some(patientOpt => patientOpt.value === id);

	// if (!isPatientAvailable) {
	// 	navigate(`/main/reports/index`, { replace: true });
	// }

	const handleExportJSONClick = () => {
		dispatch({ type: HANDLE_PRIVACY_MODAL, modal: 'privacyConfirmation', content: { rawData, id, type: 'json' } });
	}

	return (
		<div className="App">
			<header className="devices">
				<SidePanel
					authUser={auth}
					alerts={alerts}
				/>
				<div className='main-content-wrapper'>
					<ContentHeader
						contentHeader="REPORTS"
						authUser={auth}
						ui={ui}
						page="reports"
					/>
					<div className='content-table'>
						<div className='reports-dropdown-container'>
							<FontAwesomeIcon icon={faBed} style={{ color: '#F1EFFF' }} />
							<p>Select Patient:</p>
							{devices.pairedDevices && (
								<Select
									options={patientOptions}
									closeMenuOnSelect
									onChange={handleOnChange}
									defaultValue={{ value: id, label: id === 'index' ? 'Please Select a Patient' : id }}
									theme={(theme) => ({
										...theme,
										borderRadius: '6px',
										colors: {
											...theme.colors,
											neutral0: '#14025C',
											neutral20: '#F1EFFF1A',
											neutral30: '#F1EFFF66',
											primary: '#768CFF',
											primary25: '#3204EB66',
											neutral80: '#F1EFFF',
											neutral90: '#F1EFFF'
										},
									})}
								/>
							)}
						</div>
						{(id !== 'index' && id) && isLoading ? (
							<DevicesLoader height="height-small" />
						) : [
							(id !== 'index' && id) && (
								<div className='patient-report'>
									<div className='report-information device-box'>
										<div>
											<div className='col-container'>
												<FontAwesomeIcon icon={faBed} style={{ color: '#F1EFFF' }} />
												<p className="prop-title">Patient ID:</p>
												<p className='prop-content'>{id}</p>
											</div>
											<div className='col-container'>
												<FontAwesomeIcon icon={faIdBadge} style={{ color: '#F1EFFF' }} />
												<p className="prop-title">Patient Type:</p>
												<Badge
													badgeTitle={paramaterDetails?.patient}
													additionalClass={patientTypeBadge}
												/>
											</div>
										</div>
										<div>
											<div className='col-container'>
												<FontAwesomeIcon icon={faChartLine} style={{ color: '#F1EFFF' }} />
												<p className="prop-title">Pattern Type:</p>
												<Badge
													badgeTitle={paramaterDetails?.pattern}
													additionalClass={patternTypeBadge}
												/>
											</div>
											<div className='col-container'>
												<FontAwesomeIcon icon={faCalendarDays} style={{ color: '#F1EFFF' }} />
												<p className="prop-title">Data Last Fetched:</p>
												<p className='prop-content'>{paramaterDetails?.lastSeen}</p>
											</div>
										</div>
										<SecondaryButton
											label="Export Device Data in JSON"
											title="Export Device Data in JSON"
											onClick={handleExportJSONClick}
											buttonIcon={<FontAwesomeIcon icon={faFileExport} style={{ color: '#F1EFFF' }} />}
										/>
									</div>
									<div className='reports-dropdown-container'>
										<FontAwesomeIcon icon={faChartColumn} style={{ color: '#F1EFFF' }} />
										<p>Select Report:</p>
										<Select
											className='report-select-type'
											options={[
												{ value: 'Alarms', label: 'Alarms' },
												{ value: 'Pauses', label: 'Pauses' },
											]}
											onChange={(e) => setDisplayMode(e.value)}
											closeMenuOnSelect
											defaultValue={{ value: displayMode, label: displayMode }}
											theme={(theme) => ({
												...theme,
												borderRadius: '6px',
												colors: {
													...theme.colors,
													neutral0: '#14025C',
													neutral20: '#F1EFFF1A',
													neutral30: '#F1EFFF66',
													primary: '#768CFF',
													primary25: '#3204EB66',
													neutral80: '#F1EFFF',
													neutral90: '#F1EFFF'
												}
											})}
										/>
									</div>
									{
										{
											'Alarms': <Alarms alarms={alarms} paramaters={paramaters} id={id} />,
											'Pauses': <Pauses pauses={pauses} id={id} />
										}[displayMode]
									}
								</div>
							)
						]}
					</div>
				</div>
			</header>
			<ScrollUpButton />
		</div>
	)
}
