import React from 'react';
import { useDispatch } from 'react-redux';
import PauseBox from '../Device/PauseBox';
import { HANDLE_PRIVACY_MODAL } from '../../actions/types/deviceActionTypes';
import SecondaryButton from '../Common/SecondaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';

export default function Pauses({ pauses, id }) {
    const dispatch = useDispatch();
    let a1 = {};
    let finalObj = [];
    Object.values(pauses).forEach((pause, index) => {
        if (index % 2 === 0) {
            a1 = pause;
        } else {
            finalObj = [...finalObj, [a1, pause]];
        }
    });

    const handleExportPDFClick = () => {
        dispatch({ type: HANDLE_PRIVACY_MODAL, modal: 'privacyConfirmation', content: { finalObj, id, type: 'pausePdf' } });
    }
    return (
        <div className='content-table'>
            <SecondaryButton
                onClick={handleExportPDFClick}
                label="Export PDF"
                buttonIcon={<FontAwesomeIcon icon={faFileExport} style={{color: '#F1EBFF'}} />}
                style={{alignSelf: 'flex-start', marginBottom: '16px'}}
            />
            <div className='device-box-table'>
                {finalObj.map(pause => (
                    <PauseBox pause={pause} />
                ))}
            </div>
        </div>
    )
}