/* eslint-disable class-methods-use-this */
import http from '../http-common';
import { encryptAES } from '../utils/cipherMethods';
import { encryptionKey } from '../utils/rules';
class DeviceService {
	fetchAllDevices(auth) {
		return http.post(`api/devices/`, { user_key: encryptAES(auth.user_key[0], encryptionKey) });
	}

	fetchDemoDevices() {
		return http.post(`api/devices/demo`);
	}

	addSingleDevice(device, auth) {
		return http.post(`api/devices/add`, {
			device_id: encryptAES(device.deviceID, encryptionKey),
			patient_id: encryptAES(device.patientID, encryptionKey),
			patient_contact: encryptAES(device.patientContactInfo, encryptionKey),
			user_key: encryptAES(auth.user_key[0], encryptionKey)
		});
	}

	renewPairCode(device, auth) {
		return http.post(`api/devices/renewPairCode/`, {
			user_key: encryptAES(auth.user_key[0], encryptionKey),
			device_id: encryptAES(device.id.toString(), encryptionKey),
		});
	}

	deleteDevice(device, auth) {
		return http.post(`api/devices/remove`, {
			device_id: encryptAES(device.device_id, encryptionKey),
			user_key: encryptAES(auth.user_key[0], encryptionKey)
		});
	}

	updateAccessStatus(device, currentStatus, auth) {
		return http.post(`api/devices/toggleAccessStatus/`, {
			access_status: encryptAES(currentStatus === 'ON' ? 'OFF' : 'ON', encryptionKey),
			device_id: encryptAES(device.id.toString(), encryptionKey),
			user_key: encryptAES(auth.user_key[0], encryptionKey)
		});
	}

	getDeviceConfig(pairCode, pairType, auth) {
		return http.post(`api/devices/usePairCode`, {
			pair_code: encryptAES(pairCode, encryptionKey),
			pair_type: encryptAES(pairType, encryptionKey),
			user_key: encryptAES(auth.user_key[0], encryptionKey)
		});
	}

	updatePairStatus(deviceId, pairType, auth) {
		return http.post(`api/devices/updatePairStatus`, {
			device_id: encryptAES(deviceId, encryptionKey),
			pair_type: encryptAES(pairType, encryptionKey),
			user_key: encryptAES(auth.user_key[0], encryptionKey)
		});
	}

	getDummyData(auth) {
		return http.post(`api/devices/dummyData/`, { user_key: encryptAES(auth.user_key[0], encryptionKey) });
	}

}
export default new DeviceService();
