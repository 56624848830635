import { useNavigate } from 'react-router-dom';
import { faBed, faCalendarDays, faFileLines, faKey, faLaptopMedical, faMailBulk, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Badge from '../Common/Badge';
import { normalizeUserTypes } from '../../utils/constants';

export default function UserBox(props) {
    let badgeClass = 'badge-grey';
    const { create_date, user_key, user_location, user_mail, user_name, user_phone, user_type } = props;
    switch (user_type) {
        case 'db_admin':
            badgeClass = 'badge-purple';
            break;
        case 'device_expert':
            badgeClass = 'badge-pink';
            break;
        case 'doctor':
            badgeClass = 'badge-light-purple';
            break;
        default:
            badgeClass = 'badge-grey';
            break;
    }

    return (
        <div className='device-box'>
            <div className='device-box-column col1'>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faKey} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">User Key:</p>
                    <p className='prop-content'>{user_key}</p>
                </div>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faUser} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">Full Name:</p>
                    <p className='prop-content'>{user_name}</p>
                </div>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faPhone} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">Number:</p>
                    <p className='prop-content'><a href={`tel:${user_phone}`}>{user_phone}</a></p>
                </div>
            </div>
            <div className='device-box-column col2'>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faUser} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">User Type:</p>
                    <Badge
                        additionalClass={badgeClass}
                        badgeTitle={normalizeUserTypes[user_type]}
                    />
                </div>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faCalendarDays} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">Create Date:</p>
                    <p className='prop-content'>{create_date}</p>
                </div>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faMailBulk} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">E-Mail:</p>
                    <p className='prop-content'><a href={`mailto:${user_mail}`}>{user_mail}</a></p>
                </div>
            </div>
        </div >
    )
}