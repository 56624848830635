import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser,
    faKey,
    faRightFromBracket,
    faGear,
    faLocationDot,
    faEnvelope,
    faIdCard
} from '@fortawesome/free-solid-svg-icons';
import { normalizeUserTypes } from '../../utils/constants';
import { useDispatch } from 'react-redux';
import { LOGOUT_OPERATION, SET_USER_DETAILS } from '../../actions/types/authActionTypes';
import { UPDATE_ACTIVE_MODAL } from '../../actions/types/uiActionTypes';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { useOnClickOutsideRef } from '../../utils/methods';
import Badge from './Badge';
import UserService from '../../services/userService';
import { decryptAES } from '../../utils/cipherMethods';
import { encryptionKey } from '../../utils/rules';

export default function ProfileButton(props) {
    const { authUser } = props;
    const [profileOpen, setProfileOpen] = useState(false);
    const modalRef = useOnClickOutsideRef(() => setProfileOpen(false))
    let badgeClass = 'badge-grey';

    switch (authUser?.userDetails?.user_type) {
        case 'db_admin':
            badgeClass = 'badge-purple';
            break;
        case 'device_expert':
            badgeClass = 'badge-pink';
            break;
        case 'doctor':
            badgeClass = 'badge-light-purple';
            break;
        default:
            break;
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch({ type: LOGOUT_OPERATION });
    }

    useEffect(() => {
        if (authUser.isAuthorized && authUser?.userDetails.user_key === '') {
            UserService.getUserDetails(authUser).then((userDetail) => {
                const { data } = userDetail;
                const decryptedData = JSON.parse(decryptAES(data, encryptionKey)).text;
                if (decryptedData !== null) {
                    dispatch({ type: SET_USER_DETAILS, payload: decryptedData });
                } else {
                    dispatch({
                        type: SET_USER_DETAILS, payload: {
                            'user_key': "",
                            'user_name': "",
                            'user_type': 'guest',
                            'user_mail': "",
                            'user_phone': "",
                            'user_location': "",
                            'create_date': "",
                            'update_date': ""
                        }
                    });
                }
            }).catch((err) => {
                throw new Error(err);
            });
        }
    }, []);

    const handleSettingsClick = () => {
        dispatch({ type: UPDATE_ACTIVE_MODAL, payload: 'profileSettings' });
        setProfileOpen(false);
    }

    return (
        authUser.isAuthorized && (
            <div ref={modalRef} className='profile-container'>
                <button type='button' className='profile-button' onClick={() => setProfileOpen(!profileOpen)}>
                    <Jazzicon diameter={56} seed={jsNumberForAddress(authUser.user_key[0])} />
                </button>
                <div className={`profile-dropdown ${profileOpen && 'dropdownIsOpen'}`}>
                    <h1>
                        <FontAwesomeIcon icon={faUser} style={{ color: '#F1EFFF' }} />
                        <span>User Type - </span>
                        <Badge
                            additionalClass={badgeClass}
                            badgeTitle={normalizeUserTypes[authUser?.userDetails?.user_type]}
                        />
                    </h1>
                    {authUser?.userDetails.user_name !== '' && (
                        <p>
                            <FontAwesomeIcon icon={faIdCard} style={{ color: '#F1EBFF60' }} />
                            <span>Full Name - </span> <b>{authUser?.userDetails.user_name}</b>
                        </p>
                    )}
                    {authUser?.userDetails.user_location !== '' && (
                        <p>
                            <FontAwesomeIcon icon={faLocationDot} style={{ color: '#F1EBFF60' }} />
                            <span>Location - </span> <b>{authUser?.userDetails.user_location}</b>
                        </p>
                    )}
                    {authUser?.userDetails.user_mail !== '' && (
                        <p>
                            <FontAwesomeIcon icon={faEnvelope} style={{ color: '#F1EBFF60' }} />
                            <span>Mail Address - </span> <b>{authUser?.userDetails.user_mail}</b>
                        </p>
                    )}
                    <p>
                        <FontAwesomeIcon icon={faKey} style={{ color: '#F1EBFF60' }} />
                        <span>User Key - </span> <b>{authUser?.user_key[0]}</b>
                    </p>
                    <div className='profile-dropdown-divider' />
                    <button
                        type='button'
                        title="Logout"
                        onClick={handleSettingsClick}
                        className='logout-button'
                    >
                        <FontAwesomeIcon icon={faGear} style={{ color: '#F1EFFF' }} />
                        Profile Settings
                    </button>
                    <button
                        type='button'
                        title="Logout"
                        onClick={handleLogout}
                        className='logout-button'
                    >
                        <FontAwesomeIcon icon={faRightFromBracket} style={{ color: '#F1EFFF' }} />
                        Log Out
                    </button>
                </div>
            </div>
        )
    )
}