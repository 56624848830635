import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFilter,
    faPlus
} from '@fortawesome/free-solid-svg-icons';
import SecondaryButton from './SecondaryButton';
import PrimaryButton from './PrimaryButton';
import FilterDropdown from '../Device/FilterDropdown';

export default function HeaderDashboardButtons(props) {
    return (
        <>
            {props.userType === 'db_admin' && (
                <div className='filter-button-container'>
                    <SecondaryButton
                        buttonIcon={<FontAwesomeIcon icon={faFilter} style={{ color: '#F1EFFF' }} />}
                        alt="Filter Icon"
                        label="Filter"
                        dropdown
                    >
                        <FilterDropdown device_filters={props.ui.device_filters} />
                    </SecondaryButton>
                </div>
            )}
            <input className='header-search-input' value={props.searchQuery} placeholder='Search' onChange={(e) => props.setSearchQuery(e.target.value)}></input>
            {props.userType === 'db_admin' && (
                <PrimaryButton
                    buttonIcon={<FontAwesomeIcon icon={faPlus} style={{ color: '#F1EFFF' }} />}
                    label="Add Device"
                    onClick={props.onClickPrimary}
                />
            )}
            {props.userType !== 'db_admin' && (
                <PrimaryButton
                    label="Pair Device"
                    onClick={props.onClickSecondary}
                />
            )}
        </>
    )
}