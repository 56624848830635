import { AUTH_SUCCESS, LOGOUT_OPERATION, SET_USER_DETAILS, UPDATE_PROFILE_DETAILS } from '../actions/types/authActionTypes';

const ciphermed_user = JSON.parse(localStorage.getItem('ciphermed_user'));
const defaultUserDetails = {
  'user_key': "",
  'user_name': "",
  'user_type': 'guest',
  'user_mail': "",
  'user_phone': "",
  'user_location': "",
  'create_date': "",
  'update_date': ""
}

const INITIAL_AUTH_STATE = (ciphermed_user?.user_key && Object.values(ciphermed_user?.user_key) > 0)
  ? {
    isAuthorized: true, ...ciphermed_user,
    userDetails: defaultUserDetails
  }
  : {
    isAuthorized: false,
    userDetails: defaultUserDetails
  };

export const authReducer = (state = INITIAL_AUTH_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case AUTH_SUCCESS:
      localStorage.setItem('ciphermed_user', JSON.stringify({
        user_key: payload, userDetails: defaultUserDetails
      }));
      return {
        ...state, user_key: payload, isAuthorized: true, userDetails: defaultUserDetails
      };
    case LOGOUT_OPERATION:
      localStorage.removeItem('ciphermed_user');
      return {
        isAuthorized: false, userDetails: defaultUserDetails
      };
    case SET_USER_DETAILS:
      return { ...state, userDetails: payload };
    case UPDATE_PROFILE_DETAILS:
      return {
        ...state, userDetails: { ...state.userDetails, [payload.target]: payload.value }
      };
    default:
      return state;
  }
};
