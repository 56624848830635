import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { disabledSidebars } from '../../utils/rules';

export default function SidePanelButton(props) {
  let { id } = useParams();
  const navigate = useNavigate();
  const handleChangeSidePanel = (target) => {
    navigate(`/main/${target}`, { replace: false });
  }
  const isDisabled = disabledSidebars.includes(props.target.toLowerCase());
  return (
    <button
      onClick={() => !isDisabled && handleChangeSidePanel(props.target.toLowerCase())}
      id={props.id}
      className={classNames(
        'sidepanel-button',
        {
          'selected': props.buttonLabel.toLowerCase() === id,
          'disabled': isDisabled
        },
        props.additionalClass
      )}
    >
      {props.buttonIcon}
      <p title={isDisabled ? 'Upcoming!' : ''}>{props.buttonLabel}</p>
      {props.notifications && (
        <div className='sidepanel-notification'>
          {props.notificationCount}
        </div>
      )}
    </button>
  )
}
