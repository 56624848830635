import React from 'react';
import { useDispatch } from 'react-redux';
import { getAlarmDetails } from '../../utils/methods';
import { TimelineChart } from '../Charts/TimelineChart';
import Table from '../Device/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faClock, faEye, faFileExport } from '@fortawesome/free-solid-svg-icons';
import Badge from '../Common/Badge';
import { HANDLE_PARAMETER_PREVIEW } from '../../actions/types/uiActionTypes';
import { HANDLE_PRIVACY_MODAL } from '../../actions/types/deviceActionTypes';
import SecondaryButton from '../Common/SecondaryButton';

export default function Alarms({ alarms, paramaters, id }) {
    const dispatch = useDispatch();
    let alarmBadgeClass;
    const TABLEHEADERS = [
        { icon: <FontAwesomeIcon icon={faBell} />, title: 'Alarm Type' },
        { title: 'Started' },
        { title: 'Ended' },
        { icon: <FontAwesomeIcon icon={faClock} />, title: 'Duration' },
        { title: 'Alarm Count' },
        { title: 'Actions' }
    ]

    const handleExportPDFClick = () => {
        dispatch({ type: HANDLE_PRIVACY_MODAL, modal: 'privacyConfirmation', content: { alarms, id, type: 'alarmPdf' } });
    }

    return (
        <>
            <SecondaryButton
                onClick={handleExportPDFClick}
                label="Export PDF"
                buttonIcon={<FontAwesomeIcon icon={faFileExport} style={{color: '#F1EBFF'}} />}
                style={{alignSelf: 'flex-start'}}
            />
            {paramaters && alarms && <TimelineChart chartData={alarms} paramaters={paramaters} />}
            <Table tableHeaders={TABLEHEADERS}>
                {Object.values(alarms).map(alarm => {
                    const { isSingleInstance, firstSeen, lastSeen, alarmType, duration } = getAlarmDetails(alarm);
                    switch (alarmType) {
                        case 'HIGH VOLUME':
                        case 'HIGH PRESSURE':
                            alarmBadgeClass = 'badge-red';
                            break;
                        case 'LOW VOLUME':
                        case 'LOW PRESSURE':
                            alarmBadgeClass = 'badge-pink';
                            break;
                        case 'HIGH RATE':
                            alarmBadgeClass = 'badge-purple';
                            break;
                        case 'LOW RATE':
                            alarmBadgeClass = 'badge-light-purple';
                            break;
                        default:
                            alarmBadgeClass = 'badge-grey';
                            break;
                    }
                    return (
                        <tr className='table-body-row'>
                            <td data-label="Alarm Type:">
                                <Badge
                                    badgeTitle={alarmType}
                                    additionalClass={alarmBadgeClass}
                                />
                            </td>
                            <td data-label="Started:">{isSingleInstance ? 'Seen: ' : ''} {firstSeen}</td>
                            <td data-label="Ended:">{!isSingleInstance ? '' + lastSeen : ''}</td>
                            <td data-label="Duration:">{!isSingleInstance ? duration + ' seconds' : '-'}</td>
                            <td data-label="Alarm Count:" >{alarm.length}</td>
                            <td data-label="Actions:">
                                <button
                                    className='action-button'
                                    type='button'
                                    title='Preview Parameters'
                                    onClick={() => dispatch({ type: HANDLE_PARAMETER_PREVIEW, payload: { modal: 'previewParameter', content: alarm } })}
                                >
                                    <FontAwesomeIcon icon={faEye} style={{ color: '#F1EFFF', width: '16px', height: '16px' }} title='Preview Parameters' />
                                </button>
                            </td>
                        </tr>
                    )
                })}
            </Table>
        </>
    )
}