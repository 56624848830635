import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser, faUserDoctor, faUserGear
} from '@fortawesome/free-solid-svg-icons';

export default function PairUserTypeInput(props) {
    return (
        <div className='pair-device-wrapper'>
            <div className='user-types-container'>
                <div className='radio-input-container'>
                    <label for="doctor" className={`pair-radio-button ${props.checkedDoctor && 'checked'}`}>
                        <input
                            type='radio'
                            name='user_type'
                            checked={props.checkedDoctor}
                            onClick={props.onClick}
                            id='doctor'
                        />
                        <FontAwesomeIcon icon={faUserDoctor} style={{ color: '#F1EFFF', width: '44px', height: '44px' }} />
                        <p>Doctor</p>
                    </label>
                    <label for="device_expert" className={`pair-radio-button ${props.checkedDeviceExpert && 'checked'}`}>
                        <input
                            type='radio'
                            name='user_type'
                            checked={props.checkedDeviceExpert}
                            onClick={props.onClick}
                            id='device_expert'
                        />
                        <FontAwesomeIcon icon={faUserGear} style={{ color: '#F1EFFF', width: '44px', height: '44px' }} />
                        <p>Device Expert</p>
                    </label>
                </div>
            </div>
            <span className='input-label'><FontAwesomeIcon icon={faUser} style={{ color: '#F1EFFF' }} />Select User Type</span>
        </div>
    )
}