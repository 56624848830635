import { useNavigate } from 'react-router-dom';
import { faBed, faCalendarDays, faFileLines, faLaptopMedical, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Badge from '../Common/Badge';
import { normalizeUserTypes } from '../../utils/constants';

export default function DeviceBox(props) {
    let badgeClass = 'badge-grey';
    const { deviceId, patientId, patientContact, ownerType, pairDate } = props;

    const navigate = useNavigate();
    switch (ownerType) {
        case 'db_admin':
            badgeClass = 'badge-purple';
            break;
        case 'device_expert':
            badgeClass = 'badge-pink';
            break;
        case 'doctor':
            badgeClass = 'badge-light-purple';
            break;
        default:
            break;
    }

    return (
        <div className='device-box'>
            <div className='device-box-column col1'>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faLaptopMedical} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">Device:</p>
                    <p className='prop-content'>{deviceId}</p>
                </div>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faBed} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">Paired Patient:</p>
                    <p className='prop-content'>{patientId}</p>
                </div>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faPhone} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">Patient Contact Info:</p>
                    <p className='prop-content'>{patientContact}</p>
                </div>
            </div>
            <div className='device-box-column col2'>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faUser} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">Ownertype:</p>
                    <Badge
                        additionalClass={badgeClass}
                        badgeTitle={normalizeUserTypes[ownerType]}
                    />
                </div>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faCalendarDays} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">Pair Date:</p>
                    <p className='prop-content'>{pairDate}</p>
                </div>
                <div className='col-container'>
                    <button
                        className='action-button'
                        type='button'
                        onClick={() => navigate(`/main/reports/${patientId}`, { replace: false })}
                    >
                        <FontAwesomeIcon icon={faFileLines} style={{ color: '#F1EFFF', width: '16px', height: '16px' }} title='Go to Reports' />
                        Go to Reports
                    </button>
                </div>
            </div>
        </div>
    )
}