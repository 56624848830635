import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import Modal from './Modal';
import ProfileSettings from '../Device/ProfileSettings';
import PreviewMail from '../Device/PreviewMail';
import PrivacyConfirmation from '../Device/PrivacyConfirmation';
import PreviewParameter from '../Device/PreviewParameter';
import { UPDATE_ACTIVE_MODAL } from '../../actions/types/uiActionTypes';
import RemoveConfirmation from '../Device/RemoveConfirmation';

export default function ModalWrapper(props) {
    const { auth, ui, report } = props;
    const dispatch = useDispatch();

    const handleModalClick = (modalName) => {
        dispatch({ type: UPDATE_ACTIVE_MODAL, payload: ui.activeModal === modalName ? null : modalName });
    }

    return (
        <>
            {ui?.activeModal === 'profileSettings' && (
                <Modal
                    setIsOpen={handleModalClick}
                    actionLabel={`${<FontAwesomeIcon icon={faPlus} style={{ color: '#F1EBFF70' }} />} Add`}
                    modalHeader="Profile Settings"
                    modalContent={<ProfileSettings setIsOpen={handleModalClick} auth={auth} />}
                    additionalClass="profile-settings-modal"
                />
            )}
            {ui?.activeModal === 'previewMail' && (
                <Modal
                    setIsOpen={handleModalClick}
                    actionLabel={`${<FontAwesomeIcon icon={faPlus} style={{ color: '#F1EBFF70' }} />} Add`}
                    modalHeader="Preview Mail"
                    modalContent={<PreviewMail setIsOpen={handleModalClick} ui={ui} />}
                    additionalClass="preview-mail-modal"
                />
            )}
            {ui?.activeModal === 'previewParameter' && (
                <Modal
                    setIsOpen={handleModalClick}
                    actionLabel={`${<FontAwesomeIcon icon={faPlus} style={{ color: '#F1EBFF70' }} />} Add`}
                    modalHeader="Preview Parameters"
                    modalContent={<PreviewParameter setIsOpen={handleModalClick} ui={ui} report={report} />}
                    additionalClass="preview-parameters-modal"
                />
            )}
            {ui?.activeModal === 'removeConfirmation' && (
                <Modal
                    setIsOpen={handleModalClick}
                    actionLabel={`${<FontAwesomeIcon icon={faPlus} style={{ color: '#F1EBFF70' }} />} Add`}
                    modalHeader="Remove Device"
                    modalContent={<RemoveConfirmation setIsOpen={handleModalClick} auth={auth} ui={ui} />}
                    additionalClass="remove-confirmation-modal"
                />
            )}
            {ui?.activeModal === 'privacyConfirmation' && (
                <Modal
                    setIsOpen={handleModalClick}
                    actionLabel={`${<FontAwesomeIcon icon={faPlus} style={{ color: '#F1EBFF70' }} />} Add`}
                    modalHeader="Confirmation Required"
                    modalContent={<PrivacyConfirmation setIsOpen={handleModalClick} auth={auth} ui={ui} />}
                    additionalClass="privacy-confirmation-modal"
                />
            )}
        </>
    )
}