import React, { useEffect } from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import Authenticate from '../components/Authenticate'
import Header from '../components/Landing/header';
import Navbar from '../components/Landing/navbar';
import LandingBackground from '../assets/background/bg.mp4';
import LandingContent from '../components/Landing/LandingContent';
import HealthIllustration from '../assets/icon/health.svg';
import BlockchainIllustration from '../assets/icon/blockchain.svg';

export default function Landing(props) {
  const { auth } = props;
  const navigate = useNavigate();

  const isUserLoggedIn = auth.isAuthorized && window?.ethereum?._state.isUnlocked;
  useEffect(() => {
    if (isUserLoggedIn) {
      navigate(`/main/dashboard`, { replace: true });
    }
  }, [isUserLoggedIn, navigate]);

  return (
    <>
      <div className='landing'>
        <Navbar />
        <section className="section landing-hero" id="home">
          <video className='background-video' autoPlay loop muted>
            <source src={LandingBackground} type='video/mp4' />
          </video>
          <div className='landing-content'>
            <Header />
            {!isUserLoggedIn && <Authenticate />}
          </div>
        </section>
        <LandingContent
            title="About CipherMed"
            text="CipherMed is an innovative web application that has been developed to revolutionize patient follow-up in the healthcare industry. With a range of advanced features, this application provides doctors and device experts with the ability to instantly access and analyze patient data for the selected device."
            illustration={HealthIllustration}
            sectionId="about"
            additionalClass='reverse'
          />
          <LandingContent
            title="Security"
            text="One of the standout features of CipherMed is its use of blockchain technology. All patient data is securely stored on the blockchain using web3 technology, which conforms to confidentiality, integrity, and availability protocols. This ensures that all patient information remains safe, secure, and accessible only to authorized users."
            illustration={BlockchainIllustration}
            sectionId="security"
          />
      </div>
    </>
  );
}
