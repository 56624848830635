import React, { useState } from 'react';
import '../../App.css';
import SecondaryButton from '../Common/SecondaryButton';
import PrimaryButton from '../Common/PrimaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { exportJSONFile } from '../../utils/exportMethods';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { generatePDF, generatePDFFromPauses } from '../../utils/methods';

export default function PrivacyConfirmation(props) {
	const { ui } = props;
	const [isConfirmed, setIsConfirmed] = useState(false);

	const handleOnDelete = () => {
		if (isConfirmed) {
			if (ui?.privacyContent?.type === 'json') {
				exportJSONFile(ui?.privacyContent?.rawData, ui?.privacyContent?.id);
			} else if (ui?.privacyContent?.type === 'alarmPdf') {
				generatePDF(ui?.privacyContent?.alarms, ui?.privacyContent?.id);
			} else if (ui?.privacyContent?.type === 'pausePdf') {
				generatePDFFromPauses(ui?.privacyContent?.finalObj, ui?.privacyContent?.id);
			}
			props.setIsOpen('privacyConfirmation');
		}
	}
	return (
		<>
			<p className='privacy-text'>By clicking "Download," you are about to access sensitive patient information that contains Protected Health Information (PHI). This information is highly confidential and must be handled with the utmost care and responsibility.
				<br /><br />
				Please ensure that you have obtained the necessary permissions and that you are authorized to access and handle this data in compliance with all applicable laws and regulations, including but not limited to the Health Insurance Portability and Accountability Act (HIPAA) and the General Data Protection Regulation (GDPR).
				<br /><br />
				By proceeding with the download, you acknowledge that you understand the importance of maintaining the privacy and security of this information. Any unauthorized use, disclosure, or mishandling of this data may result in legal consequences.
				<br /><br />
				If you have any doubts or concerns regarding the proper use and handling of this information, please contact the appropriate authority or your system administrator before proceeding.</p>
			<div className='col-container'>
				<p className="prop-title">I acknowledge that I am authorized to access and handle this sensitive patient information.</p>
				<label className="switch">
					<input
						id={'key'}
						value='on'
						checked={isConfirmed}
						type="checkbox"
						onChange={() => setIsConfirmed(!isConfirmed)}
					/>
					<span className="slider round"></span>
				</label>
			</div>
			<div className='add-device-button-container'>
				<SecondaryButton
					label="Cancel"
					title="Cancel"
					onClick={() => props.setIsOpen('removeConfirmation')}
				/>
				<PrimaryButton
					label="Download"
					onClick={handleOnDelete}
					buttonIcon={<FontAwesomeIcon icon={faDownload} style={{ color: '#F1EFFF' }} />}
					additionalClass={!isConfirmed ? 'disabled' : ''}
				/>
			</div>
		</>
	);
}
