import { useEffect, useRef } from 'react'
import moment from 'moment';
import { ethers } from "ethers";
import { toast } from 'react-toastify';
import JsPDF from 'jspdf';
import "jspdf-autotable";
import { NORMALIZE_ALARM_CODES, ALARM_INTERVAL, NORMALIZE_PATTERN_CODES, NORMALIZE_PATIENT_CODES } from "./constants";
import { DeviceContractAddress } from "../config";
import DeviceAbi from '../dapp/build/contracts/DeviceContract.json';

export const convertEpochtoDate = (
	(timestamp) => {
		const time = Date.now() - Math.abs(timestamp);
		let readableTime;
		let units;
		if (time > (1000 * 60 * 60 * 24 * 365)) {
			readableTime = parseInt(time / (1000 * 60 * 60 * 24 * 365), 10);
			units = 'years';
		} else if (time > (1000 * 60 * 60 * 24 * 30)) {
			readableTime = parseInt(time / (1000 * 60 * 60 * 24 * 30), 10);
			units = 'months';
		} else if (time > (1000 * 60 * 60 * 24 * 7)) {
			readableTime = parseInt(time / (1000 * 60 * 60 * 24 * 7), 10);
			units = 'weeks';
		} else if (time > (1000 * 60 * 60 * 24)) {
			readableTime = parseInt(time / (1000 * 60 * 60 * 24), 10);
			units = 'days';
		} else if (time > (1000 * 60 * 60)) {
			readableTime = parseInt(time / (1000 * 60 * 60), 10);
			units = 'hours';
		} else if (time > (1000 * 60)) {
			readableTime = parseInt(time / (1000 * 60), 10);
			units = 'minutes';
		} else {
			readableTime = parseInt(time / (1000), 10);
			units = 'seconds';
		}
		return `${readableTime} ${units}`;
	}
);

export const convertEpochtoReadableFormat = (
	(timestamp) => {
		const time = Math.abs(timestamp);
		let readableTime;
		let units;
		if (time > (1000 * 60 * 60 * 24 * 365)) {
			readableTime = parseInt(time / (1000 * 60 * 60 * 24 * 365), 10);
			units = 'years';
		} else if (time > (1000 * 60 * 60 * 24 * 30)) {
			readableTime = parseInt(time / (1000 * 60 * 60 * 24 * 30), 10);
			units = 'months';
		} else if (time > (1000 * 60 * 60 * 24 * 7)) {
			readableTime = parseInt(time / (1000 * 60 * 60 * 24 * 7), 10);
			units = 'weeks';
		} else if (time > (1000 * 60 * 60 * 24)) {
			readableTime = parseInt(time / (1000 * 60 * 60 * 24), 10);
			units = 'days';
		} else if (time > (1000 * 60 * 60)) {
			readableTime = parseInt(time / (1000 * 60 * 60), 10);
			units = 'hours';
		} else if (time > (1000 * 60)) {
			readableTime = parseInt(time / (1000 * 60), 10);
			units = 'minutes';
		} else {
			readableTime = parseInt(time / (1000), 10);
			units = 'seconds';
		}
		return `${readableTime} ${units}`;
	}
);

export const convertEpochtoDatePair = (
	(timestamp) => {
		const time = Date.now() - Math.abs(timestamp);
		const readableTime = parseInt(time / (1000), 10);
		return { readableTime };
	}
);

export const sortWithEpochDate = (dataSet, sortType) => {
	const multiplier = sortType.indexOf('Asc') > -1 ? 1 : -1;
	const target = sortType.indexOf('create') > -1 ? 'creation_date' : 'pair_date';
	return dataSet.sort((a, b) => {
		const compDate = moment.unix(a[target]).diff(moment.unix(b[target]), 'seconds') > 0;
		if (compDate) {
			return -1 * multiplier;
		} else {
			return 1 * multiplier;
		}
	});
}

export const isHexValid = (_hex) => (!['0x00'].includes(_hex));
export const currDate = () => (moment().format('DD.MM.YYYY_HH-mm-ss'));

export const useOnClickOutsideRef = (callback, initialValue = null) => {
	const elementRef = useRef(initialValue)
	useEffect(() => {
		function handler(event) {
			if (!elementRef.current?.contains(event.target)) {
				callback()
			}
		}
		window.addEventListener('click', handler)
		return () => window.removeEventListener('click', handler)
	}, [callback])
	return elementRef
}

export const displayInfoToast = (message) => {
	toast.info(message, {
		position: "bottom-right",
		autoClose: 3000,
		hideProgressBar: false,
		newestOnTop: false,
		closeOnClick: false,
		rtl: false,
		pauseOnFocusLoss: true,
		draggable: true,
		pauseOnHover: true,
		theme: "dark"
	})
}

export const displayErrorToast = (message) => {
	toast.error(message, {
		position: "bottom-right",
		autoClose: 3000,
		hideProgressBar: false,
		newestOnTop: false,
		closeOnClick: true,
		rtl: false,
		pauseOnFocusLoss: true,
		draggable: true,
		pauseOnHover: true,
		theme: "dark"
	})
}

export const getTimeDiff = (a, b) => (a.diff(b, 'seconds'));

export const alarmProcessor = (tempAlarmObjects) => {
	let alarmObjects = [];
	let alarmGroup = 0;

	tempAlarmObjects.forEach((temp, index) => {
		const currTimeObj = moment(temp?.DateTime, 'DD/MM/YYYY hh:mm:ss');
		const currAlarmCode = temp?.Alarm;
		const nextAlarmCode = tempAlarmObjects[index + 1]?.Alarm;
		const nextTimeObj = moment(tempAlarmObjects[index + 1]?.DateTime, 'DD/MM/YYYY hh:mm:ss');
		const timeDiff = Math.abs(getTimeDiff(currTimeObj, nextTimeObj))
		if (timeDiff < ALARM_INTERVAL) {
			if (currAlarmCode === nextAlarmCode) {
				alarmObjects = [
					...alarmObjects,
					{
						DateTime: temp.DateTime,
						alarmCode: temp.Alarm,
						alarmType: NORMALIZE_ALARM_CODES[temp.Alarm],
						alarmGroup: alarmGroup
					}
				];
			} else {
				alarmGroup++;
			}
		} else {
			alarmGroup++;
		}
	});

	const finalAlarmObject = alarmObjects.reduce((x, y) => {
		(x[y.alarmGroup] = x[y.alarmGroup] || []).push(y);
		return x;
	}, {});

	return finalAlarmObject;
}

export const parameterProcessor = (tempParameterObjects) => {
	let parameterObjects = [];
	let parameterGroup = 0;

	tempParameterObjects.forEach((temp, index) => {
		const currTimeObj = moment(temp?.DateTime, 'DD/MM/YYYY hh:mm:ss');
		const nextTimeObj = moment(tempParameterObjects[index + 1]?.DateTime, 'DD/MM/YYYY hh:mm:ss');
		const timeDiff = Math.abs(getTimeDiff(currTimeObj, nextTimeObj))
		if (timeDiff < 60 * ALARM_INTERVAL) {
			parameterObjects = [
				...parameterObjects,
				{
					DateTime: temp.DateTime,
					patameters: temp.Parameters,
					parameterGroup: parameterGroup
				}
			];
		} else {
			parameterGroup++;
		}
	});

	const finalParameterObject = parameterObjects.reduce((x, y) => {
		(x[y.parameterGroup] = x[y.parameterGroup] || []).push(y);
		return x;
	}, {});

	return Object.values(finalParameterObject);
}

export const getAlarmDetails = selector => {
	const isSingleInstance = selector.length === 1;
	const firstSeen = selector.at(-1).DateTime;
	const lastSeen = selector[0].DateTime;
	const alarmType = selector[0].alarmType;
	const duration = getTimeDiff(moment(lastSeen, 'DD/MM/YYYY hh:mm:ss'), moment(firstSeen, 'DD/MM/YYYY hh:mm:ss'));
	return { isSingleInstance, firstSeen, lastSeen, alarmType, duration };
}

export const getParameterDetails = (selector, setIsLoading) => {
	if (selector.length > 0) {
		const firstSeen = selector.at(-1)[0]?.DateTime;
		const lastSeen = selector[0][0]?.DateTime;
		const parameterKeys = selector.at(-1)[0]?.patameters;
		const instanceSize = Object.values(selector).length;
		const pattern = NORMALIZE_PATTERN_CODES[selector.at(-1)[0]?.patameters?.pattern];
		const patient = NORMALIZE_PATIENT_CODES[selector.at(-1)[0]?.patameters?.patient];
		if (setIsLoading) {
			setIsLoading(false);
		}
		return { firstSeen, lastSeen, parameterKeys, instanceSize, pattern, patient };
	}
}

export const getAllPairedDevices = async () => {
	try {
		const { ethereum } = window;
		if (ethereum) {
			const provider = new ethers.providers.Web3Provider(ethereum)
			const signer = provider.getSigner();
			const DeviceContract = new ethers.Contract(
				DeviceContractAddress,
				DeviceAbi.abi,
				signer
			)

			let allPairedDevices = await DeviceContract.getMyDevices();
			return allPairedDevices;
		}
	} catch (error) {
		console.error('err', error);
		if (error.toString().indexOf('getAddress') > -1) {
			window.location.assign("/");
		}
	}
}

export const convertBase64ToFile = (base64String, fileName) => {
	let arr = base64String.split(',');
	let mime = arr[0].match(/:(.*?);/)[1];
	let bstr = atob(arr[1]);
	let n = bstr.length;
	let uint8Array = new Uint8Array(n);
	while (n--) {
		uint8Array[n] = bstr.charCodeAt(n);
	}
	let file = new File([uint8Array], fileName, { type: mime });
	return file;
}

export const generatePDFFromPauses = (pauses, id) => {
	const unit = "pt";
	const size = "A4";
	const orientation = "landscape";
	const marginLeft = 40;
	const doc = new JsPDF(orientation, unit, size);
	doc.setFontSize(15);
	const title = `Pauses Triggered on ${id}'s Device`;
	const headers = [["Paused at", "Started at", "Duration"]];
	const data = Object.values(pauses).map(pause => {
		const firstActionTime = pause[1].DateTime;
		const secondActionTime = pause[0].DateTime;
		const duration = convertEpochtoReadableFormat(moment.duration(moment(firstActionTime, 'DD.MM.YYYY HH:mm:ss').diff(moment(secondActionTime, 'DD.MM.YYYY HH:mm:ss'))).asMilliseconds());

		return [firstActionTime, secondActionTime, duration];
	});
	let content = {
		startY: 50,
		head: headers,
		body: data,
		headStyles: { fillColor: [254, 117, 254] }, // Cells in first column centered and green
		styles: { fillColor: [241, 239, 255], textColor: [18, 4, 88], font: 'courier', halign: 'center' },
	};
	doc.text(title, marginLeft, 20);
	doc.autoTable(content);
	doc.save(`CipherMed_${id}_PauseReport.pdf`);
};

export const generatePDF = (alarms, id) => {
	const unit = "pt";
	const size = "A4";
	const orientation = "landscape";
	const marginLeft = 40;
	const doc = new JsPDF(orientation, unit, size);
	doc.setFontSize(15);
	const title = `Alarms Triggered on ${id}'s Device`;
	const headers = [["Alarm Type", "Started", "Ended", "Duration", "Alarm Count"]];
	const data = Object.values(alarms).map(elt => {
		const { firstSeen, lastSeen, alarmType, duration } = getAlarmDetails(elt);
		return [alarmType, firstSeen, lastSeen, `${duration} seconds`, `${elt.length} times`];
	});
	let content = {
		startY: 50,
		head: headers,
		body: data,
		headStyles: { fillColor: [255, 18, 79] }, // Cells in first column centered and green
		styles: { fillColor: [241, 239, 255], textColor: [18, 4, 88], font: 'courier', halign: 'center' },
	};
	doc.text(title, marginLeft, 20);
	doc.autoTable(content);
	doc.save(`CipherMed_${id}_AlarmReport.pdf`);
};
