import React from 'react';
import ProfileButton from './ProfileButton';
import HeaderDashboardButtons from './HeaderDashboardButtons';
import { useDispatch } from 'react-redux';
import { CHAIN_IS_ALLOWED } from '../../actions/types/uiActionTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash,
    faPaperPlane
} from '@fortawesome/free-solid-svg-icons';
import SecondaryButton from './SecondaryButton';
import { resendAllMails } from '../../utils/helpers';

export default function ContentHeader(props) {
    const { ui, authUser } = props;
    const dispatch = useDispatch();
    const userType = authUser.userDetails.user_type || 'Guest';
    //todo: profile section can be moved to another component
    return (
        <div className={`content-header-container ${props.page === 'alerts' ? 'alerts-header-container' : ''}`}>
            <p className='content-header'>
                {props.contentHeader}
            </p>
            {!ui.isChainAllowed &&
                (
                    <div className='chainAlert'>
                        CipherMed currently only operates on Sepolia Testnet Network.
                        <button
                            className='action-button'
                            type='button'
                            onClick={() => dispatch({ type: CHAIN_IS_ALLOWED })}
                        >
                            <FontAwesomeIcon icon={faTrash} style={{ color: '#F1EFFF' }} title='Remove Device' />
                        </button>
                    </div>
                )
            }
            <div className='header-button-wrapper'>
                {props.page === 'dashboard' &&
                    <HeaderDashboardButtons
                        userType={userType}
                        ui={ui}
                        {...props}
                    />
                }
                {(props.page === 'alerts' && props.auth.userDetails.user_type === 'db_admin') &&
                    <SecondaryButton
                        label="Resend Waiting Mails"
                        title="Resend Waiting Mails"
                        onClick={() => resendAllMails(props.alerts, props.auth, dispatch)}
                        buttonIcon={<FontAwesomeIcon icon={faPaperPlane} style={{ color: '#F1EFFF' }} />}
                    />
                }
                <ProfileButton
                    userType={userType}
                    authUser={props.authUser}
                />
            </div>
        </div>
    )
}
