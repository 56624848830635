import axios from "axios";
import AlertService from '../services/alertService';
import DeviceService from '../services/deviceService';
import {
    RENEW_PAIR_CODE_SUCCESS, RENEW_PAIR_CODE_ERROR, DELETE_DEVICE_SUCCESS, DELETE_DEVICE_ERROR,
    UPDATE_ACCESS_STATUS_SUCCESS, UPDATE_ACCESS_STATUS_ERROR, SHOW_PAIR_CODE
} from '../actions/types/deviceActionTypes';
import { SET_DUMMY_DATA, CATEGORIZE_DUMMY_DATA } from '../actions/types/reportActionTypes';
import { DELETE_ALERT_SUCCESS, DELETE_ALERT_ERROR, RESEND_MAIL_SUCCESS, RESEND_ALL_MAILS_SUCCESS, RESEND_ALL_MAILS_ERROR } from '../actions/types/alertActionTypes';
import { alarmProcessor, parameterProcessor } from "./methods";

export const showPairCode = (device, dispatch) => {
    navigator.clipboard.writeText(device);
    dispatch({ type: SHOW_PAIR_CODE, payload: 'Pair Code Copied Successfully' })
}

export const renewPairCode = (device, auth, dispatch, setUpdateRT, setUpdateRequired) => (
    DeviceService.renewPairCode(device, auth).then((devices) => {
        dispatch({ type: RENEW_PAIR_CODE_SUCCESS, payload: devices.data });
        setUpdateRequired(true);
        setUpdateRT(true);
    }).catch((err) => {
        dispatch({ type: RENEW_PAIR_CODE_ERROR, payload: err });
    })
)

export const updateAccessStatus = (device, auth, currentStatus, dispatch, setUpdateRequired) => (
    DeviceService.updateAccessStatus(device, currentStatus, auth).then((devices) => {
        dispatch({ type: UPDATE_ACCESS_STATUS_SUCCESS, payload: devices.data });
        setUpdateRequired(true);
    }).catch((err) => {
        dispatch({ type: UPDATE_ACCESS_STATUS_ERROR, payload: err });
    })
)

export const deleteDevice = (device, auth, dispatch, setUpdateRequired) => (
    DeviceService.deleteDevice(device, auth).then((devices) => {
        dispatch({ type: DELETE_DEVICE_SUCCESS, payload: devices.data, deletedDevice: device });
        if (setUpdateRequired) {
            setUpdateRequired(true);
        }
    }).catch((err) => {
        dispatch({ type: DELETE_DEVICE_ERROR, payload: err });
    })
)

export const processDummyData = (rawData, dispatch) => {
    const tempAlarmObjects = Object.values(rawData).filter(r => Object.keys(r).includes('Alarm')).filter(tmp => tmp.Alarm !== 0);
    const PauseObjects = Object.values(rawData).filter(r => Object.keys(r).includes('Pause'));
    const tempParameterObjects = Object.values(rawData).filter(r => Object.keys(r).includes('Parameters'));
    const finalAlarmObject = alarmProcessor(tempAlarmObjects);
    const finalParameterObject = parameterProcessor(tempParameterObjects);
    return dispatch({ type: CATEGORIZE_DUMMY_DATA, finalAlarmObject, PauseObjects, finalParameterObject });
};

export const getDummyData = async (dispatch) => {
    const { data } = await axios.get(`https://ciphermed.app/logs/dummyLogs.json`);
    processDummyData(data, dispatch);
    dispatch({ type: SET_DUMMY_DATA, payload: data });
};

export const deleteAlert = (alert, auth, dispatch) => (
    AlertService.deleteSingleAlert(alert, auth).then((alerts) => {
        dispatch({ type: DELETE_ALERT_SUCCESS, payload: alerts.data.pid });
    }).catch((err) => {
        dispatch({ type: DELETE_ALERT_ERROR, payload: err });
    })
)

export const resendSingleMail = (alert, auth, dispatch) => (
    AlertService.updateSingleMail(alert, auth).then((alerts) => {
        dispatch({ type: RESEND_MAIL_SUCCESS, payload: alert.id });
    })
)

export const resendAllMails = (alerts, auth, dispatch) => {
    const isAllSend = Object.values(alerts).every(alert => alert.status === 'SENT');
    if (!isAllSend) {
        AlertService.updateAllPending(auth).then((alerts) => {
            dispatch({ type: RESEND_ALL_MAILS_SUCCESS });
        }).catch((err) => {
            dispatch({ type: RESEND_ALL_MAILS_ERROR, payload: 'Alerts cannot be sent again!' });
        })
    } else {
        dispatch({ type: RESEND_ALL_MAILS_ERROR, payload: 'There is no pending emails in the queue!' });
    }
}
