import React from 'react';
import '../../App.css';
import SecondaryButton from '../Common/SecondaryButton';
import { useDispatch } from 'react-redux';
import PrimaryButton from '../Common/PrimaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteDevice } from '../../utils/helpers';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default function RemoveConfirmation(props) {
    const dispatch = useDispatch();
    const { auth, ui } = props;

    const handleOnDelete = () => {
        deleteDevice(ui?.device, auth, dispatch);
        props.setIsOpen('removeConfirmation');
    }
    return (
        <>
            <div className='result-animation-wrapper warning'>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                    <circle class="path circle" fill="#E38800" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                    <line class="path line" fill="none" stroke="#FFFFFF" stroke-width="12" stroke-linecap="round" stroke-miterlimit="10" x1="66" y1="30" x2="66" y2="82" />
                    <line class="path line" fill="none" stroke="#FFFFFF" stroke-width="12" stroke-linecap="round" stroke-miterlimit="10" x1="66" y1="100" x2="66" y2="100" />
                </svg>
                <p>Are you sure you want to remove this device?</p>
            </div>
            <div className='add-device-button-container'>
                <SecondaryButton
                    label="Cancel"
                    title="Cancel"
                    onClick={() => props.setIsOpen('removeConfirmation')}
                />
                <PrimaryButton
                    label="Remove"
                    onClick={handleOnDelete}
                    buttonIcon={<FontAwesomeIcon icon={faTrash} style={{ color: '#F1EFFF' }} />}
                />
            </div>
        </>
    );
}
