import React from 'react'
import SidePanelButton from './SidePanelButton'
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHouse,
	faFileLines,
	faBell,
	faBrain,
	faPills,
	faUser
} from '@fortawesome/free-solid-svg-icons';
import ProfileButton from './ProfileButton';

export default function SidePanel(props) {
	const { authUser, alerts } = props;
	const navigate = useNavigate();

	const userType = authUser.userDetails.user_type || 'Guest';
	const location = useLocation().pathname;
	const notificationCount = Object.values(alerts).filter(alert => alert.status !== 'SENT').length || 0;
	return (
		<div className='sidepanel-wrapper'>
			<div className='sidepanel-header'>
				<h1 id='ciphermedLogo' onClick={() => navigate(`/main/dashboard`, { replace: false })}>CipherMed</h1>
				<ProfileButton
					userType={userType}
					authUser={authUser}
				/>
			</div>
			<div className='sidepanel-contents-container'>
				<div className='sidepanel-menu'>
					<SidePanelButton
						buttonLabel="DASHBOARD"
						target="dashboard"
						buttonIcon={<FontAwesomeIcon icon={faHouse} style={{ color: '#F1EFFF' }} />}
						title="Home"
						additionalClass={location === '/main/dashboard' ? 'sidepanel-active' : 'sidepanel-inactive'}
					/>
					{authUser.userDetails.user_type !== 'db_admin' && (
						<SidePanelButton
							buttonLabel="REPORTS"
							target="reports/index"
							buttonIcon={<FontAwesomeIcon icon={faFileLines} style={{ color: '#F1EFFF' }} />}
							title="Reports"
							additionalClass={location.indexOf('/main/reports') > -1 ? 'sidepanel-active' : 'sidepanel-inactive'}
						/>
					)}
					{authUser.userDetails.user_type === 'db_admin' && (
						<SidePanelButton
							buttonLabel="USERS"
							target="users"
							buttonIcon={<FontAwesomeIcon icon={faUser} style={{ color: '#F1EFFF' }} />}
							title="Users"
							additionalClass={location.indexOf('/main/users') > -1 ? 'sidepanel-active' : 'sidepanel-inactive'}
						/>
					)}
					<SidePanelButton
						buttonLabel="MAIL ALERTS"
						target="mail-alerts"
						buttonIcon={<FontAwesomeIcon icon={faBell} style={{ color: '#F1EFFF' }} />}
						title="Mail Alerts"
						additionalClass={location === '/main/mail-alerts' ? 'sidepanel-active' : 'sidepanel-inactive'}
						notifications={notificationCount > 0}
						notificationCount={notificationCount}
					/>
					<SidePanelButton
						buttonLabel="AI"
						target="ai"
						buttonIcon={<FontAwesomeIcon icon={faBrain} style={{ color: '#F1EFFF' }} />}
						title="AI"
					/>
					<SidePanelButton
						buttonLabel="TELEMEDICINE"
						target="telemedicine"
						buttonIcon={<FontAwesomeIcon icon={faPills} style={{ color: '#F1EFFF' }} />}
						title="Telemedicine"
					/>
				</div>
			</div>
		</div>
	)
}
