import React from 'react';
import { faArrowDownLong, faArrowRightLong, faClock, faLaptopMedical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Badge from '../Common/Badge';
import { convertEpochtoReadableFormat } from '../../utils/methods';

export default function PauseBox(props) {
    const { pause } = props;

    const firstActionTime = pause[1].DateTime;
    const secondActionTime = pause[0].DateTime;
    const duration = convertEpochtoReadableFormat(moment.duration(moment(firstActionTime, 'DD.MM.YYYY HH:mm:ss').diff(moment(secondActionTime, 'DD.MM.YYYY HH:mm:ss'))).asMilliseconds());

    return (pause && pause[0].Pause !== pause[1].Pause && !pause[0].Pause &&
        <div className='pause-box'>
            <div className='device-box-column'>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faLaptopMedical} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">Device Status:</p>
                    {pause[1].Pause ? (<Badge
                        additionalClass="badge-red"
                        badgeTitle='Paused'
                    />) : (<Badge
                        additionalClass="badge-green"
                        badgeTitle='Working'
                    />)}
                </div>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faClock} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">Date - Time:</p>
                    <p className='prop-content'> {firstActionTime}</p>
                </div>
            </div>
            <div className='device-box-column' id='icon-container'>
                <FontAwesomeIcon icon={faArrowRightLong} style={{ color: '#F1EFFF' }} />
                <FontAwesomeIcon icon={faArrowDownLong} style={{ color: '#F1EFFF' }} />
                <span id='device-box-duration'>{duration}</span>
            </div>
            <div className='device-box-column'>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faLaptopMedical} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">Device Status:</p>
                    {pause[0].Pause ? (<Badge
                        additionalClass="badge-red"
                        badgeTitle='Paused'
                    />) : (<Badge
                        additionalClass="badge-green"
                        badgeTitle='Working'
                    />)}
                </div>
                <div className='col-container'>
                    <FontAwesomeIcon icon={faClock} style={{ color: '#F1EFFF' }} />
                    <p className="prop-title">Date - Time:</p>
                    <p className='prop-content'> {secondActionTime}</p>
                </div>
            </div>
        </div>
    )
}