/* eslint-disable no-useless-escape */
import React from 'react';
import '../../App.css';
import { NORMALIZE_PATTERN_CODES, NORMALIZE_PATIENT_CODES } from '../../utils/constants';

export default function PreviewParameter(props) {
	const { ui, report } = props;
	const { paramaters } = report;
	const searchParam = ui.previewParameterContent[0].DateTime;
	const temp = paramaters.find(parameter => parameter[0].DateTime == searchParam)[0];
	const date = temp.DateTime;
	const content = temp.patameters;
	console.log({ date, content })
	return (
		<div className='add-device-wrapper'>
			<div className='table-scroll'>
				<table className='parameters-table'>
					<tr className='table-body-row'>
						<th>Date:</th>
						<td>{date}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Alarm:</th>
						<td>{content.alarm}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Apne Time:</th>
						<td>{content.apneTime}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Battery Level:</th>
						<td>{content.batteryLevel}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Cycle Time:</th>
						<td>{content.cycleTime}</td>
					</tr>
					<tr className='table-body-row'>
						<th>E-Sense:</th>
						<td>{content.eSense}</td>
					</tr>
					<tr className='table-body-row'>
						<th>I-Sense:</th>
						<td>{content.iSense}</td>
					</tr>
					<tr className='table-body-row'>
						<th>IE-Ratio:</th>
						<td>{content.ieRatio}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Max Pinsp:</th>
						<td>{content.maxPinsp}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Max Rate:</th>
						<td>{content.maxRate}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Max VTidal:</th>
						<td>{content.maxVTidal}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Min Pinsp:</th>
						<td>{content.minPinsp}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Min Rate:</th>
						<td>{content.minRate}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Min VTidal:</th>
						<td>{content.minVTidal}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Mode:</th>
						<td>{content.mode}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Patient Type:</th>
						<td>{NORMALIZE_PATIENT_CODES[content.patient]}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Pattern Type:</th>
						<td>{NORMALIZE_PATTERN_CODES[content.pattern]}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Device Status:</th>
						<td>{content.paused ? 'Paused' : 'Working'}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Peep:</th>
						<td>{content.peep}</td>
					</tr>
					<tr className='table-body-row'>
						<th>PinSp:</th>
						<td>{content.pinsp}</td>
					</tr>
					<tr className='table-body-row'>
						<th>PSup:</th>
						<td>{content.psup}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Rate:</th>
						<td>{content.rate}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Ratio:</th>
						<td>{content.ratio}</td>
					</tr>
					<tr className='table-body-row'>
						<th>Rise Time:</th>
						<td>{content.riseTime}</td>
					</tr>
					<tr className='table-body-row'>
						<th>T-Exp:</th>
						<td>{content.tExp}</td>
					</tr>
					<tr className='table-body-row'>
						<th>T-Insp:</th>
						<td>{content.tInsp}</td>
					</tr>
				</table>
			</div>
			<div className='add-device-button-container'>
				<button className='primary-button' onClick={() => props.setIsOpen('previewParameter')}>Close</button>
			</div>
		</div >
	);
}
