import React from "react";
import { de } from "date-fns/locale";
import { format } from "date-fns";
import moment from 'moment';
// import { exportChart } from '../../utils/exportMethods';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from "chart.js";

import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chartjs-adapter-date-fns";
// import SecondaryButton from "../Common/SecondaryButton";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFileExport } from "@fortawesome/free-solid-svg-icons";
// import PrimaryButton from "../Common/PrimaryButton";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  TimeScale
);

export const TimelineChart = ({ chartData, paramaters }) => {
  let alarmObjects = [];
  Object.values(chartData).forEach(value => {
    const int = moment(value.at(-1).DateTime, "DD.MM.YYYY hh:mm:ss").valueOf();
    const last = moment(value[0].DateTime, "DD.MM.YYYY hh:mm:ss").valueOf();
    const tempParams = value && paramaters?.find(parameter => parameter[0]?.DateTime === value[0]?.DateTime);
    const params = tempParams ? tempParams.at(0)?.patameters : {};
    alarmObjects = [
      ...alarmObjects,
      {
        Start: int,
        End: int + 350000000,
        Last: last,
        EventName: value[0].alarmType,
        Duration: `${(last - int) / 1000} Seconds`,
        Params: params || {},
        EventSource: value[0].alarmType,
      }
    ];
  });

  (Tooltip.positioners).custom = (elements, eventPosition) => {
    return {
      x: eventPosition.x,
      y: eventPosition.y
    };
  };

  const labels = [...new Set(alarmObjects.map((event) => event.EventSource))];

  const labelGrouping = [];

  const eventNames = [...new Set(alarmObjects.map((event) => event.EventName))];
  const eventColors = eventNames
    .map((val, i) => {
      var color = `hsl(${(i * (360 / (eventNames.length || 1))) % 360
        },100%,50%, 1)`;
      return color;
    })
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  const datasets = alarmObjects.map((event) => {
    let start = event.Start;
    let end = event.End;

    let stack = undefined;
    let firstStackEntry = false;

    if (labelGrouping[event.EventSource] === undefined) {
      stack = { Stack: "Stack0", LastDate: event.End };
      labelGrouping[event.EventSource] = [stack];
      firstStackEntry = true;
    } else {
      labelGrouping[event.EventSource].forEach((item, index) => {
        if (
          stack === undefined &&
          item.LastDate <= event.Start
        ) {
          stack = { ...item };
          item.LastDate = event.End;
        }
      });
      if (stack === undefined) {
        const stackIndex = labelGrouping[event.EventSource].length;
        stack = { Stack: "Stack" + stackIndex, LastDate: event.End };
        labelGrouping[event.EventSource].push(stack);
        firstStackEntry = true;
      }
    }

    let data = labels.map(() => null);

    if (!firstStackEntry) {
      start -= stack.LastDate;
      end -= stack.LastDate;
    }
    data[labels.indexOf(event.EventSource)] = [
      start,
      end,
      "Between " + format(event.Start, "dd.MM.yyyy hh:mm:ss") +
      " - " +
      format(event.Last, "dd.MM.yyyy hh:mm:ss")
      + " | Parameters:"
      + " vTidal: " + event?.Params.vTidal
      + " | pInsp: " + event?.Params.pinsp
      + " | Apne Time: " + event?.Params.apneTime
      + " | Cycle Time: " + event?.Params.cycleTime
      + " | eSense: " + event?.Params.eSense
      + " | iSense: " + event?.Params.iSense
      + " | tInsp: " + event?.Params.tInsp
      + " | pSup: " + event?.Params.psup
      + " | Mode: " + event?.Params.mode
    ];

    return {
      label: `${event.Duration} on ${event.EventName}`,
      data: data,
      skipNull: true,
      backgroundColor: eventColors[eventNames.indexOf(event.EventName)],
      stack: event.EventSource + "_" + stack.Stack,
      datalabels: {
        formatter: () => event.EventName
      }
    };
  });

  const data = {
    labels,
    datasets: datasets
  };

  const options = {
    indexAxis: "y",
    plugins: {
      tooltip: {
        callbacks: {
          title: () => "",
          afterBody: (items) =>
            data.datasets[items[0].datasetIndex].data[items[0].dataIndex][2],
          label: (item) => data.datasets[item.datasetIndex].label
        },
        position: "custom"
      },
      legend: {
        display: false
      },
      title: {
        display: true,
        text: "Created Alerts"
      },
      datalabels: {
        color: "white",
        anchor: "start",
        align: "right",
        display: (context) => {
          return context.dataset.data[context.dataIndex] !== null
            ? "auto"
            : false;
        },
        font: function (context) {
          var width = context.chart.width;
          var size = width / 100;
          return {
            weight: "bold",
            size: 0
          };
        }
      }
    },
    resizeDelay: 5,
    responsive: true,
    scales: {
      x: {
        min: Math.min(...alarmObjects.map((event) => event.Start)),
        max: Math.max(...alarmObjects.map((event) => event.End)),
        ticks: {
          autoSkip: true,
          maxTicksLimit: 50
        },
        type: "time",
        time: {
          displayFormats: {
            millisecond: "HH:mm:ss.SSS",
            second: "dd.MM.yyyy hh:mm",
            minute: "dd.MM.yyyy hh:mm",
            hour: "dd.MM.yyyy hh:mm",
            day: "dd.MM.yyyy hh:mm",
            week: "dd.MM.yyyy hh:mm",
            month: "dd.MM.yyyy hh:mm",
            quarter: "dd.MM.yyyy hh:mm",
            year: "dd.MM.yyyy hh:mm"
          },
          unit: "hour"
        },
        adapters: {
          date: {
            locale: de
          }
        },
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  };
  // const url_base64 = document.querySelector('#myChart')?.toDataURL('image/png');
  return (
    <>
      {/* <div className="chart-button-wrapper">
      <PrimaryButton
        label="Export Device Data in PNG"
        title="Export Device Data in PNG"
        onClick={() => exportChart(url_base64)}
        buttonIcon={<FontAwesomeIcon icon={faFileExport} style={{ color: '#F1EFFF' }} />}
      />
    </div> */}
      <Bar id="myChart" options={options} data={data} />
    </>
  );
}