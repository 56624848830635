import React, { useState, useEffect } from 'react';
import { ethers } from "ethers";
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPlus
} from '@fortawesome/free-solid-svg-icons';
import DeviceService from '../services/deviceService';
import AddDevice from '../components/Device/AddDevice';
import PairDevice from '../components/Device/PairDevice';
import '../App.css';
import { FETCH_DEVICES_SUCCESS } from '../actions/types/deviceActionTypes';
import SidePanel from '../components/Common/SidePanel';
import DeviceItem from '../components/Device/DeviceItem';
import ContentHeader from '../components/Common/ContentHeader';
import Modal from '../components/Common/Modal';
import { sortWithEpochDate, isHexValid } from '../utils/methods';
import { convertEpochtoDatePair } from '../utils/methods';
import { showPairCode, deleteDevice, updateAccessStatus, renewPairCode } from '../utils/helpers';
import ScrollUpButton from '../components/Common/ScrollUpButton';
import { decryptAES } from '../utils/cipherMethods';
import { encryptionKey } from '../utils/rules';
import DeviceBox from '../components/Device/DeviceBox';
import DevicesLoader from '../components/Common/DevicesLoader';

export default function Devices({ props, isPairedDevicesLoading }) {
	const { devices, auth, ui, alerts } = props;
	const { device_filters } = ui;
	const [isOpen, setIsOpen] = useState(false);
	const [isDevicesLoading, setIsDevicesLoading] = useState(Object.values(devices.baseDevices).length === 0);
	const [isOpenPair, setIsOpenPair] = useState(false);
	const [updateRequired, setUpdateRequired] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const dispatch = useDispatch();
	const pairedDeviceCount = devices?.pairedDevices?.filter(paired => isHexValid(paired.deviceId._hex)).length;
	const allDeviceCount = devices?.baseDevices?.length;
	const userType = auth?.userDetails.user_type || 'Guest';

	useEffect(() => {
		if (auth.isAuthorized && auth?.userDetails.user_type === 'db_admin' && (Object.values(devices.baseDevices).length === 0 || updateRequired)) {
			DeviceService.fetchAllDevices(auth).then((devices) => {
				const { data } = devices;
				const decryptedData = JSON.parse(decryptAES(data, encryptionKey)).text;
				dispatch({ type: FETCH_DEVICES_SUCCESS, payload: decryptedData });
				setUpdateRequired(false);
				setIsDevicesLoading(false);
			}).catch((err) => {
				throw new Error(err);
			});
		}
	}, [auth, updateRequired]);

	const renderBaseDevices = () => (isDevicesLoading ? (<DevicesLoader height="height-small" />) : (sortWithEpochDate(Object.values(devices.baseDevices), device_filters.sort.prop)
		.filter(base => (
			base.device_id?.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1
			|| base.doctor_id?.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1
			|| base.patient_id?.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1
			|| base.device_expert_id?.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1
			|| base.patient_contact?.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1)
		)
		.filter(base => (device_filters.isAccessible.prop !== null ? (base.access_status === (device_filters.isAccessible.prop ? 'ON' : 'OFF')) : true))
		.filter(base => (device_filters.isPairCodeExpired.prop !== null ? (device_filters.isPairCodeExpired.prop ? (convertEpochtoDatePair(base.expire_date).readableTime > 0) && (base.doctor_id === null || base.device_expert_id === null) : false) : true))
		.filter(base => (device_filters.pairedWithDoctor.prop !== null ? (device_filters.pairedWithDoctor.prop ? (base.doctor_id !== null) : true) : true))
		.filter(base => (device_filters.pairedWithExpert.prop !== null ? (device_filters.pairedWithExpert.prop ? (base.device_expert_id !== null) : true) : true))
		.map(device => (
			<DeviceItem
				device={device}
				updateAccessStatus={updateAccessStatus}
				showPairCode={showPairCode}
				onClickDelete={deleteDevice}
				onClickEdit={renewPairCode}
				dispatch={dispatch}
				auth={auth}
				updateRequired={updateRequired}
				setUpdateRequired={setUpdateRequired}
			/>
		)))
	);

	const renderPairedDevices = () => (isPairedDevicesLoading ? (
		<div className='pair-devices-loaders'>
			<DevicesLoader  height="height-large" />
			<DevicesLoader  height="height-large" />
		</div>
	) : (
		<div className='content-table'>
			<div className='device-box-table'>
				{devices.pairedDevices && Object.values(devices.pairedDevices).reverse().filter(pairedDevice => pairedDevice[3] &&
					(isHexValid(pairedDevice.deviceId._hex) && isHexValid(pairedDevice.patientId._hex) && isHexValid(pairedDevice.patientContactInfo._hex))
					&& (ethers.utils.parseBytes32String(pairedDevice.deviceId._hex).toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1
						|| ethers.utils.parseBytes32String(pairedDevice.patientId._hex).toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1
						|| ethers.utils.parseBytes32String(pairedDevice.patientContactInfo._hex).toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1)).map(pairedDevice =>
							(isHexValid(pairedDevice.deviceId._hex) || isHexValid(pairedDevice.patientId._hex)) && (
								<DeviceBox
									deviceId={ethers.utils.parseBytes32String(pairedDevice.deviceId._hex)}
									patientId={ethers.utils.parseBytes32String(pairedDevice.patientId._hex)}
									patientContact={ethers.utils.parseBytes32String(pairedDevice.patientContactInfo._hex)}
									ownerType={isHexValid(pairedDevice.ownerType._hex) && ethers.utils.parseBytes32String(pairedDevice.ownerType._hex)}
									pairDate={isHexValid(pairedDevice.pairDate._hex) && ethers.utils.parseBytes32String(pairedDevice.pairDate._hex)}
								/>
							))}
			</div>
		</div>
	));

	return (
		<div className="App">
			{isOpen &&
				<Modal
					setIsOpen={setIsOpen}
					actionLabel={`${<FontAwesomeIcon icon={faPlus} style={{ color: '#F1EBFF70' }} />} Add`}
					modalHeader="Add New Device"
					modalContent={<AddDevice setIsOpen={setIsOpen} auth={auth} />}
					additionalClass="add-device-modal"
				/>}
			{isOpenPair &&
				<Modal
					setIsOpen={setIsOpenPair}
					actionLabel="Pair"
					modalHeader="Pair New Device"
					modalContent={<PairDevice setIsOpen={setIsOpenPair} ui={ui} auth={auth} />}
					additionalClass="pair-device-modal"
				/>}
			<div className="devices">
				<SidePanel
					authUser={auth}
					alerts={alerts}
				/>
				<div className='main-content-wrapper'>
					<ContentHeader
						contentHeader={userType === 'db_admin' ? `ALL DEVICES ${allDeviceCount > 0 ? `(${allDeviceCount})` : ''}` : `PAIRED DEVICES ${pairedDeviceCount > 0 ? `(${pairedDeviceCount})` : ''}`}
						authUser={auth}
						ui={ui}
						onClickPrimary={setIsOpen}
						onClickSecondary={setIsOpenPair}
						page="dashboard"
						setSearchQuery={setSearchQuery}
						searchQuery={searchQuery}
					/>
					{userType === 'db_admin' ? (
						<ul className='content-table'>
							{renderBaseDevices()}
						</ul>
					) : (renderPairedDevices())}
				</div>
				<ScrollUpButton />
			</div>
		</div>
	);
}
